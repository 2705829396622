import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MenuItem from "@mui/material/MenuItem";

export const getItemSearch = ({
  title,
  setState,
  type,
  valueState,
  optionSelect,
  dataSearch,
  handleSearch,
  step,
  gridNumber,
}) => {
  type = type || "text";
  return (
    <Grid
      item
      xxl={gridNumber || 2}
      xl={gridNumber ? (2 * gridNumber > 12 ? 12 : 2 * gridNumber) : 4}
      sx={type === "button" && { display: "flex", alignItems: "end" }}
    >
      {type !== "button" && (
        <Grid item xs={12}>
          <MDBox p={2}>
            <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>{title}</MDTypography>
          </MDBox>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: "-24px" }}>
        <MDBox p={2} pb={4} sx={{ display: "flex" }}>
          {(type === "text" || type === "number") && (
            <MDInput
              id={type === "number" ? "input_center" : ""}
              sx={{ width: "100%" }}
              type={type}
              value={valueState}
              inputProps={{
                step: step ? step : 1,
              }}
              variant="standard"
              onChange={(e) => setState(e.target.value)}
            />
          )}
          {type === "date" && (
            <MDInput
              className="input_search_date"
              type="date"
              variant="standard"
              value={valueState}
              onChange={(e) => setState(e.target.value)}
            />
          )}
          {type === "select" && (
            <MDInput
              variant="standard"
              select
              labelid="demo-simple-select-label"
              id="demo-simple-select"
              value={valueState}
              InputProps={{
                classes: { root: "select-input-styles" },
              }}
              fullWidth
              sx={{ minWidth: "160px", margin: "-8px", marginLeft: "0px" }}
              onChange={(e) => setState(e.target.value)}
            >
              <MenuItem key={-1} value="">
                None
              </MenuItem>
              {optionSelect.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </MDInput>
          )}
          {type === "textarea" && (
            <textarea
              rows="4"
              style={{
                resize: "none",
                fontSize: "14px",
                padding: "8px 12px",
                fontFamily: "inherit",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid rgb(227 221 221)",
              }}
              value={valueState}
              onChange={(e) => setState(e.target.value)}
            />
          )}
          {type === "button" && (
            <MDButton
              variant="contained"
              color="error"
              sx={{ marginTop: "32px", width: "137px" }}
              onClick={() => handleSearch(dataSearch)}
            >
              Tìm kiếm
            </MDButton>
          )}
        </MDBox>
      </Grid>
    </Grid>
  );
};

export const getUrlSearch = (data) => {
  let urlSearch = "?";
  Object.keys(data).forEach((item, i) => {
    urlSearch += item + "=" + data[item] + "&";
  });
  return urlSearch;
};
