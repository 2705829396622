import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const ItemAutocomplete = ({ value, options, setState, title, width }) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: width ? width : 500 }}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} label={title} />}
      onChange={(e, value) => setState(value)}
    />
  );
};
