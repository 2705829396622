import OrderTables from "layouts/order";
import OrderDetailTables from "layouts/order/orderDetail";
import CreateOrder from "layouts/order/create";
import ProductTables from "layouts/product";
import Icon from "@mui/material/Icon";
import CategoryTables from "layouts/category";
import AttributeTables from "layouts/attribute";
import CreateAttribute from "layouts/attribute/create";
import EditAttribute from "layouts/attribute/edit";
import CreateCategory from "layouts/category/create";
import EditCategory from "layouts/category/edit";
import EditProductMapping from "layouts/product/create/productMapping/edit";
import ProductDetailTables from "layouts/product/productDetail";
import CreateProduct from "layouts/product/create";
import EditProduct from "layouts/product/edit";
import CreateAttributeProduct from "layouts/product/create/attribute";
import UserTables from "layouts/user";
import UserDetailTables from "layouts/user/userDetail";
import CustomerTables from "layouts/customer";
import CommissionTables from "layouts/commission";
import CommissionDetail from "layouts/commission/detail";
import SignIn from "layouts/authentication/sign-in";

// @mui icons

const routes = [
  {
    type: "collapse",
    name: "Đơn hàng",
    key: "order",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/order",
    component: <OrderTables />,
  },
  {
    route: "/order/:id",
    component: <OrderDetailTables />,
  },
  {
    route: "/order/create",
    component: <CreateOrder />,
  },
  {
    type: "collapse",
    name: "Sản phẩm",
    key: "product",
    icon: <Icon fontSize="small">production_quantity_limits</Icon>,
    route: "/product",
    component: <ProductTables />,
  },
  {
    route: "/product/:id",
    component: <ProductDetailTables />,
  },
  {
    route: "/product/create",
    component: <CreateProduct />,
  },
  {
    route: "/product/edit/:productCode",
    component: <EditProduct />,
  },
  {
    route: "/product/create/attribute/:id",
    component: <CreateAttributeProduct />,
  },
  {
    route: "/product/edit/product-mapping",
    component: <EditProductMapping />,
  },
  {
    type: "collapse",
    name: "Loại sản phẩm",
    key: "category",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/category",
    component: <CategoryTables />,
  },
  {
    route: "/category/create",
    component: <CreateCategory />,
  },
  {
    route: "/category/edit",
    component: <EditCategory />,
  },
  {
    type: "collapse",
    name: "Thuộc tính sản phẩm",
    key: "attribute",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/attribute",
    component: <AttributeTables />,
  },
  {
    route: "/attribute/create",
    component: <CreateAttribute />,
  },
  {
    route: "/attribute/edit",
    component: <EditAttribute />,
  },
  {
    type: "collapse",
    name: "Cộng tác viên/Đại lý",
    key: "user",
    icon: <Icon fontSize="small">supervisor_account</Icon>,
    route: "/user",
    component: <UserTables />,
  },
  {
    route: "/user/:id",
    component: <UserDetailTables />,
  },
  {
    type: "collapse",
    name: "Khách hàng",
    key: "customer",
    icon: <Icon fontSize="small">support_agent</Icon>,
    route: "/customer",
    component: <CustomerTables />,
  },
  {
    type: "collapse",
    name: "Hoa hồng",
    key: "commission",
    icon: <Icon fontSize="small">interests</Icon>,
    route: "/commission",
    component: <CommissionTables />,
  },
  {
    route: "/commission/:id",
    component: <CommissionDetail />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
