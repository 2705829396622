import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import Switch from "@mui/material/Switch";
import createOrderData from "layouts/order/create/createOrderData";
import { getItemSearch } from "components/Search/itemSearch";
import { TittlePage } from "components/TitlePage";
import { ItemAutocomplete } from "components/AutoComplete";
import { useState } from "react";
import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";
import { useNavigate } from "react-router-dom";

function CreateOrder() {
  const crxFetch = useFetch();
  const navigate = useNavigate();

  const {
    columns,
    rows,
    handleAddNewProduct,
    products,
    customers,
    users,
    dataProduct,
    totalPriceOrder,
  } = createOrderData();

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [isFindCustomer, setIsFindCustomer] = useState(false);
  const [customer, setCustomer] = useState("");
  const [product, setProduct] = useState("");
  const [user, setUser] = useState("");

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const ItemTextBox = ({ title, value, gridNumber }) => {
    return (
      <Grid item xl={gridNumber || 2} md={2 * gridNumber || 4}>
        <Grid item xs={12}>
          <MDBox p={2}>
            <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>{title}</MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "-24px" }}>
          <MDBox p={2} pb={4} sx={{ display: "flex" }}>
            <MDTypography sx={{ fontSize: "12px", color: "#000000" }}>{value}</MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  const createCustomer = async (data) => {
    try {
      return await crxFetch.post(appConfig.customers, data);
    } catch (err) {
      console.log(err);
    }
  };

  const createOrder = async (data) => {
    try {
      return await crxFetch.post(appConfig.orders, data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    console.log({
      customer,
      user,
      isFindCustomer,
      dataProduct,
      fullName,
      phone,
      address,
      isFindCustomer,
    });
    // if (isFindCustomer && !customer?.id) return alert("Hãy điền đầy đủ thông tin khách hàng.");
    if (!isFindCustomer && !(fullName && phone && address) && (phone || fullName || address))
      return alert("Hãy điền đầy đủ thông tin khách hàng.");
    if (!dataProduct.length) return alert("Hãy điền đầy đủ thông tin sản phẩm được bán.");
    if (!user?.id) return alert("Hãy điền thông tin user quản lý.");

    let customerId = customer?.id;
    if (!isFindCustomer && fullName && phone && address) {
      const newCustomer = await createCustomer({ fullName, phone, address });
      customerId = newCustomer.id;
    }

    const productList = dataProduct.map((item) => {
      return {
        id: item.id,
        quantity: item.quantity,
        productAttr: item.attr
          .map((attribute) => {
            if (attribute.indexAttr) return attribute.indexAttr;
          })
          .filter((indexAttr) => indexAttr),
      };
    });

    await createOrder({
      customerId,
      productList,
      userId: user.id,
    });
    return navigate("/order");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({ title: "Tạo đơn hàng", back: "/order", create: true, handleSave })}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid
                container
                spacing={6}
                sx={{ marginBottom: isFindCustomer ? "12px" : "-24px", marginLeft: "-24px" }}
              >
                {titleItem("Thông tin khách hàng(Không bắt buộc)")}
                <Grid item xs={3}>
                  <Grid item xs={12}>
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#797979",
                        marginLeft: "24px",
                        marginBottom: "24px",
                      }}
                    >
                      Tìm kiếm khách hàng cũ
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                    <MDBox m={2} pb={4} sx={{ display: "flex" }}>
                      <Switch
                        color="info"
                        checked={isFindCustomer}
                        onChange={() => {
                          if (isFindCustomer) setCustomer({});
                          setIsFindCustomer(!isFindCustomer);
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                {isFindCustomer && (
                  <Grid item xs={5}>
                    <Grid item xs={12} mb={2} ml={2}>
                      <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
                        Chọn khách hàng
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                      <MDBox p={2} pb={4} sx={{ display: "flex" }}>
                        {ItemAutocomplete({
                          options: customers.map((customer) => {
                            return {
                              label: `${customer.fullName} - ${customer.phone} - ${customer.address}`,
                              ...customer,
                            };
                          }),
                          setState: setCustomer,
                          title: "Customer",
                          width: 700,
                        })}
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {!isFindCustomer && (
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginTop: "-72px" }}>
                  {getItemSearch({ title: "Họ tên khách hàng", setState: setFullName })}
                  {getItemSearch({ title: "Số điện thoại", setState: setPhone })}
                  {getItemSearch({ title: "Địa chỉ", setState: setAddress, gridNumber: 4 })}
                </Grid>
              )}

              {isFindCustomer && (
                <Grid
                  container
                  spacing={6}
                  sx={{ marginBottom: "-24px", marginTop: customer?.fullName ? "-72px" : "-24px" }}
                >
                  {customer?.fullName &&
                    ItemTextBox({
                      title: "Họ tên khách hàng",
                      value: customer.fullName,
                      gridNumber: 2.5,
                    })}
                  {customer?.phone &&
                    ItemTextBox({ title: "Số điện thoại", value: customer.phone })}
                  {customer?.address &&
                    ItemTextBox({ title: "Địa chỉ", value: customer.address, gridNumber: 4 })}
                </Grid>
              )}
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thông tin đơn hàng")}

                <Grid item xs={12}>
                  <Grid container spacing={6}>
                    <Grid item xs={5}>
                      <Grid item xs={12}>
                        <MDBox p={3}>
                          <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
                            Chọn sản phẩm
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                        <MDBox p={2} pb={4} sx={{ display: "flex" }}>
                          {ItemAutocomplete({
                            options: products.map((product) => {
                              return {
                                label: `${product.code} - ${product.name}`,
                                id: product.id,
                                code: product.code,
                              };
                            }),
                            setState: setProduct,
                            title: "Product",
                            value: product,
                            width: 700,
                          })}
                        </MDBox>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", alignItems: "end" }}>
                      <Grid item xs={12}>
                        <MDButton
                          disabled={!product?.id}
                          variant="outlined"
                          color="error"
                          sx={{
                            width: "100px",
                            height: "35px",
                            color: "#D31C23",
                            border: "1px solid #D31C23",
                            borderRadius: "7px",
                            marginBottom: "32px",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            handleAddNewProduct(product);
                            setProduct("");
                          }}
                        >
                          <Icon
                            sx={{
                              scale: "1.3",
                              marginRight: "4px",
                              color: "#D31C23",
                              fontWeight: 500,
                            }}
                          >
                            add
                          </Icon>
                          Thêm
                        </MDButton>
                      </Grid>
                    </Grid>

                    <div style={{ width: "25%", display: "flex", alignItems: "center" }}>
                      {ItemTextBox({
                        title: "Tổng hóa đơn",
                        value: totalPriceOrder.toLocaleString("en-US"),
                        gridNumber: 12,
                      })}
                    </div>
                  </Grid>
                </Grid>

                {rows.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    className="table_in_card"
                    sx={{ paddingLeft: "0px !important" }}
                  >
                    <Card>
                      <MDBox pt={3}>
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("User quản lý")}
                <Grid container spacing={6} mt={-6}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <MDBox p={2} pl={3}>
                        <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
                          Chọn user quản lý đơn hàng
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                      <MDBox p={2} pl={3} pb={4} sx={{ display: "flex" }}>
                        {ItemAutocomplete({
                          options: users.map((user) => {
                            return {
                              label: `${user.fullName} - ${user.phone} - ${user.address}`,
                              id: user.id,
                            };
                          }),
                          setState: setUser,
                          title: "User",
                          value: user,
                          width: 700,
                        })}
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateOrder;
