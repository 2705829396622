import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import commissionData from "layouts/commission/commissionData";
import { getItemSearch } from "components/Search/itemSearch";
import { statusOptionCommission } from "../../config/configStatus";
import { useState } from "react";
import appConfig from "../../config/index";
import Pagination from "@mui/material/Pagination";

function CommissionTables() {
  const { columns, rows, searchCommissions, pages, getCommissions } = commissionData();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [agentId, setAgentId] = useState("");

  const handleSearch = async (data) => {
    await searchCommissions(data, 0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={6} sx={{ marginBottom: "-16px" }}>
                {getItemSearch({
                  title: "Trạng thái",
                  setState: setStatus,
                  type: "select",
                  optionSelect: statusOptionCommission,
                  valueState: status,
                })}
                {getItemSearch({ title: "Tên khách hàng", setState: setName })}
                {getItemSearch({ title: "SDT khách hàng", setState: setPhone })}
                {getItemSearch({ title: "Id agent", setState: setAgentId })}
                {getItemSearch({
                  type: "button",
                  dataSearch: {
                    name,
                    phone,
                    status,
                    agentId,
                  },
                  handleSearch,
                })}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{
                    defaultValue: appConfig.sizePage,
                    entries: [5, 10, 15, 20, 25, 50],
                  }}
                  showTotalEntries={false}
                  noEndBCommission
                />
                {pages > 1 && (
                  <MDBox p={2}>
                    <Pagination
                      count={pages}
                      color="primary"
                      onChange={async (e, value) => {
                        if (!name && !phone && !status && !agentId)
                          return await getCommissions(value - 1);
                        await searchCommissions(
                          {
                            name,
                            phone,
                            status,
                            agentId,
                          },
                          value - 1
                        );
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CommissionTables;
