import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { TittlePage } from "components/TitlePage";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionCommission } from "../../../config/configStatus";
import { useLocation } from "react-router-dom";
import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function CommissionDetail() {
  const crxFetch = useFetch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const agentId = searchParams.get("agentId");
  const sdt = searchParams.get("sdt");

  const [status, setStatus] = useState("");
  const [commission, setCommission] = useState({});

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const getItemUser = ({ title, value, gridNumber }) => {
    return (
      <Grid item xl={gridNumber || 2} md={gridNumber * 2 || 4} key={title}>
        {title && (
          <Grid item xs={12}>
            <MDBox p={2}>
              <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>{title}</MDTypography>
            </MDBox>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: "-24px" }}>
          <MDBox p={2}>
            <MDBox sx={{ fontSize: "14px", color: "#000000" }}>{value}</MDBox>
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  const confirmCommission = async () => {
    try {
      await crxFetch.put(`${appConfig.commissions}/${id}/payment`).then((res) => {
        setStatus("Đã thanh toán");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getDetailCommission = () => {
    let phone = sdt === "undefined" ? "" : sdt;
    try {
      crxFetch.get(`${appConfig.commissions}/?phone=${phone}&agentId=${agentId}`).then((res) => {
        const itemCommission = res.data.find((item) => item.id === +id);
        setCommission(itemCommission);
        setStatus(itemCommission.commissionStatusOrder);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetailCommission();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({ title: id, back: "/commission" })}

          {commission.customer && (
            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {titleItem("Thông tin khách hàng")}
                  {getItemUser({
                    title: "Họ tên khách hàng",
                    value: commission.customer?.fullName,
                  })}
                  {getItemUser({ title: "Số điện thoại", value: commission.customer?.phone })}
                  {getItemUser({
                    title: "Địa chỉ",
                    value: commission.customer?.address,
                    gridNumber: 4,
                  })}
                </Grid>
              </Card>
            </Grid>
          )}

          {commission.user && (
            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {titleItem("Thông tin CTV/Đại lý")}
                  {getItemUser({ title: "Họ tên", value: commission.user?.fullName })}
                  {getItemUser({ title: "Số điện thoại", value: commission.user?.phone })}
                  {getItemUser({ title: "Chiết khấu(%)", value: commission.user?.discount })}
                  {getItemUser({
                    title: "Địa chỉ",
                    value: commission.user?.address,
                    gridNumber: 4,
                  })}
                </Grid>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thông tin đơn hàng")}
                <Grid item xs={12} sx={{ marginBottom: "-12px" }}>
                  <Grid container spacing={6} mt={-7}>
                    {status &&
                      getItemUser({
                        title: "Trạng thái",
                        value: getItemStatus({
                          statusOption: statusOptionCommission.map((item) => {
                            return { ...item, value: item.title };
                          }),
                          value: status,
                        }),
                      })}

                    {status === "Chờ thanh toán" && (
                      <Grid item xxl={3} xl={4}>
                        <Grid item xs={12} sx={{ marginTop: "-24px", marginBottom: "-84px" }}>
                          <MDBox p={2} pb={0} mt={4}>
                            <MDButton
                              variant="contained"
                              color="error"
                              sx={{ marginTop: "20px", marginBottom: "60px" }}
                              onClick={async () => await confirmCommission()}
                            >
                              Xác nhận thanh toán
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={6} mt={-7}>
                    {getItemUser({
                      title: "Giá",
                      value: commission.price?.toLocaleString("en-US"),
                    })}
                    {getItemUser({
                      title: "Phí",
                      value: commission.fee?.toLocaleString("en-US"),
                    })}
                    {getItemUser({
                      title: "Thuế",
                      value: commission.tax?.toLocaleString("en-US"),
                    })}
                    {getItemUser({
                      title: "Chiết khấu(%)",
                      value: commission.commission,
                    })}
                    {getItemUser({
                      title: "Tỉ lệ chiết khấu(%)",
                      value: commission.commissionDiscount,
                    })}
                    {getItemUser({
                      title: "Tổng tiền",
                      value: commission.principal?.toLocaleString("en-US"),
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CommissionDetail;
