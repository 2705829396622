import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../../hook/useFetch";
import appConfig from "../../config/index";
import { getUrlSearch } from "components/Search/itemSearch";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionUser } from "../../config/configStatus";

export default function userData() {
  const crxFetch = useFetch();

  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState(0);

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Mã CTV/Đaị lý": (
        <Link ml={-1} to={`/user/${item.id}`} style={{ color: "#2962ff" }}>
          {item.fullName}
        </Link>
      ),
      Tên: <MDBox ml={-1}>{item.userName}</MDBox>,
      "Địa chỉ": <MDBox ml={-1}>{item.address}</MDBox>,
      "Ngày tham gia": <MDBox ml={-1}>{item.createDate}</MDBox>,
      "Số điện thoại": <MDBox ml={-1}>{item.phone}</MDBox>,
      "Số CCCD/CMND": <MDBox ml={-1}>{"?!"}</MDBox>,
      Role: <MDBox ml={-1}>{item.roles[0].name}</MDBox>,
      "Chiết khấu": (
        <MDBox ml={-1} pl={1} pr={1} sx={{ color: "red" }}>
          {item.discount || 0}%
        </MDBox>
      ),
      "Trạng thái": getItemStatus({ statusOption: statusOptionUser, value: item.status }),
    };
  };

  const searchUsers = (data, page) => {
    const urlSearch = getUrlSearch(data);
    try {
      crxFetch
        .get(`${appConfig.users}${urlSearch}size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setUsers(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getUsers = async (page) => {
    try {
      await crxFetch
        .get(`${appConfig.users}?size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setUsers(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUsers(0);
  }, []);

  return {
    columns: [
      { Header: "Stt", accessor: "Stt", width: "5%", align: "center" },
      { Header: "Mã CTV/Đaị lý", accessor: "Mã CTV/Đaị lý", width: "7%", align: "center" },
      { Header: "Tên", accessor: "Tên", width: "10%", align: "center" },
      { Header: "Địa chỉ", accessor: "Địa chỉ", width: "15%", align: "center" },
      { Header: "Ngày tham gia", accessor: "Ngày tham gia", width: "10%", align: "center" },
      { Header: "Số điện thoại", accessor: "Số điện thoại", width: "10%", align: "center" },
      { Header: "Số CCCD/CMND", accessor: "Số CCCD/CMND", width: "10%", align: "center" },
      { Header: "Chiết khấu", accessor: "Chiết khấu", width: "10%", align: "center" },
      { Header: "Role", accessor: "Role", width: "15%", align: "center" },
      { Header: "Trạng thái", accessor: "Trạng thái", width: "5%", align: "center" },
    ],
    rows: users,
    searchUsers,
    pages,
    getUsers,
  };
}
