import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../../hook/useFetch";
import appConfig from "../../config/index";
import { getUrlSearch } from "components/Search/itemSearch";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionProduct } from "../../config/configStatus";
import { itemAttribute, itemStatus } from "components/ItemAttribute";

export default function productData() {
  const crxFetch = useFetch();

  const [products, setProducts] = useState([]);
  const [pages, setPages] = useState(0);

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Mã sản phẩm": (
        <Link ml={-1} to={`/product/${item.id}`} style={{ color: "#2962ff" }}>
          {item.code}
        </Link>
      ),
      "Tên sản phẩm": <MDBox ml={-1}>{item.name}</MDBox>,
      "Bảo hành": <MDBox ml={-1}>{item.warrantyTime}</MDBox>,
      "Giá bán": <MDBox ml={-1}>{item.unitPrice.toLocaleString("en-US")}</MDBox>,
      "Trạng thái": getItemStatus({ statusOption: statusOptionProduct, value: item.status }),
    };
  };

  const searchProduces = (data, page) => {
    const urlSearch = getUrlSearch(data);
    try {
      crxFetch
        .get(`${appConfig.products}${urlSearch}size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setProducts(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getProducts = async (page) => {
    try {
      await crxFetch
        .get(`${appConfig.products}?size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setProducts(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProducts(0);
  }, []);

  return {
    columns: [
      { Header: "Stt", accessor: "Stt", width: "5%", align: "center" },
      { Header: "Mã sản phẩm", accessor: "Mã sản phẩm", width: "10%", align: "center" },
      { Header: "Tên sản phẩm", accessor: "Tên sản phẩm", width: "25%", align: "center" },
      { Header: "Bảo hành", accessor: "Bảo hành", width: "10%", align: "center" },
      { Header: "Giá bán", accessor: "Giá bán", width: "15%", align: "center" },
      { Header: "Trạng thái", accessor: "Trạng thái", width: "10%", align: "center" },
    ],
    rows: products,
    searchProduces,
    pages,
    getProducts,
  };
}
