import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import categoryData from "layouts/category/categoryData";
import { getItemSearch } from "components/Search/itemSearch";
import { statusOptionCategory } from "../../config/configStatus";
import { useState } from "react";
import { Link } from "react-router-dom";
import appConfig from "../../config/index";
import Pagination from "@mui/material/Pagination";

function CategoryTables() {
  const { columns, rows, searchCategories, pages, getCategories } = categoryData();

  const handleSearch = async (data) => {
    await searchCategories(data, 0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {/* <Grid item xs={12}>
            <Card>
              <Grid container spacing={6} sx={{ marginBottom: "-16px" }}>
                {getItemSearch({ title: "Mã yêu cầu", setState: setOrderCode })}
                {getItemSearch({ title: "Tên CTV/Đại lý", setState: setCTV })}
                {getItemSearch({
                  title: "Trạng thái",
                  setState: setStatus,
                  type: "select",
                  optionSelect: statusOptionCategory,
                  valueState: status,
                })}
                {getItemSearch({
                  title: "Ngày yêu cầu",
                  setState: setFromDate,
                  type: "date",
                })}
                {getItemSearch({
                  title: "Ngày thanh toán",
                  setState: setToDate,
                  type: "date",
                })}
                {getItemSearch({
                  type: "button",
                  dataSearch: {
                    CTV,
                    orderCode,
                    status,
                    fromDate: fromDate ? new Date(fromDate).toLocaleDateString("es-CL") : "",
                    toDate: toDate ? new Date(toDate).toLocaleDateString("es-CL") : "",
                  },
                  handleSearch,
                })}
              </Grid>
            </Card>
          </Grid> */}
          <Grid item xs={12} mt={-6}>
            <Link to="/category/create">
              <MDButton variant="contained" color="error" sx={{ marginTop: "32px" }}>
                Tạo loại sản phẩm
              </MDButton>
            </Link>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} className="category">
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{
                    defaultValue: appConfig.sizePage,
                    entries: [5, 10, 15, 20, 25, 50],
                  }}
                  showTotalEntries={false}
                  noEndBCategory
                />
                {pages > 1 && (
                  <MDBox p={2}>
                    <Pagination
                      count={pages}
                      color="primary"
                      onChange={async (e, value) => {
                        return await getCategories(value - 1);
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CategoryTables;
