import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";

export const TittlePage = ({
  title,
  back,
  create,
  titleCreate,
  colorCreate,
  handleSave,
  isDisabled,
  isDelete,
  titleDelete,
  colorDelete,
  handleDelete,
  width,
}) => {
  return (
    <Grid item xs={12}>
      <Card sx={{ padding: "8px", fontSize: "14px" }}>
        <Grid container>
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <MDBox p={1}>
              <Link to={back} variant="h5" style={{ color: "#344767" }}>
                <Icon sx={{ scale: "1.2", marginRight: "8px", paddingTop: "3px" }}>
                  arrow_back_outlined
                </Icon>
                {title}
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <Grid container sx={{ display: "flex", justifyContent: "flex-end" }}>
              {create && (
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <MDButton
                    disabled={isDisabled}
                    variant="contained"
                    color={colorCreate || "error"}
                    sx={{
                      backgroundColor: colorCreate ? colorCreate : "#D31C23",
                      width: width ? width : "137px",
                      color: colorCreate ? "#D31C23" : "#fff",
                      border: colorCreate ? "1px solid #D31C23" : "none",
                      borderRadius: "7px",
                      fontSize: "14px",
                    }}
                    onClick={() => handleSave()}
                  >
                    {titleCreate ? titleCreate : "Lưu"}
                  </MDButton>
                </Grid>
              )}
              {isDelete && (
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <MDButton
                    variant="contained"
                    color={colorDelete || "error"}
                    sx={{
                      backgroundColor: colorDelete ? colorDelete : "#D31C23",
                      width: width ? width : "137px",
                      color: colorDelete ? "#D31C23" : "#fff",
                      border: colorDelete ? "1px solid #D31C23" : "none",
                      borderRadius: "7px",
                      fontSize: "14px",
                    }}
                    onClick={() => handleDelete()}
                  >
                    {titleDelete ? titleDelete : "Xóa"}
                  </MDButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
