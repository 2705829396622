import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { TittlePage } from "components/TitlePage";
import orderDetailData from "layouts/order/orderDetail/orderDetailData";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionOrder, statusOptionUser } from "../../../config/configStatus";
import { useLocation } from "react-router-dom";
import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ItemAutocomplete } from "components/AutoComplete";
import { AlertDialogSlide } from "components/PopUpNotification";

function OrderDetailTables() {
  const crxFetch = useFetch();
  const location = useLocation();
  const { columns, rows, order } = orderDetailData();
  const searchParams = new URLSearchParams(location.search);
  const statusParams = searchParams.get("status");
  const orderCode = searchParams.get("orderCode");
  const userId = searchParams.get("userId");
  const { id } = useParams();
  const customerJson = searchParams.get("customer");
  const customer = customerJson !== "undefined" ? JSON.parse(customerJson) : {};
  const [isUpdateCTV, setIsUpdateCTV] = useState(false);
  const [CTV, setCTV] = useState({});
  const [status, setStatus] = useState(statusParams);
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState({});
  const [open, setOpen] = useState(false);

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const getItemUser = ({ title, value, gridNumber }) => {
    return (
      <Grid item xl={gridNumber || 2} md={gridNumber * 2 || 4} key={title}>
        {title && (
          <Grid item xs={12}>
            <MDBox p={2}>
              <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>{title}</MDTypography>
            </MDBox>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: "-24px" }}>
          <MDBox p={2}>
            <MDBox sx={{ fontSize: "14px", color: "#000000" }}>{value}</MDBox>
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  const getInfoUser = async (id) => {
    if (id === "undefined") return;
    await crxFetch.get(`${appConfig.users}/admin/${id}/detail`).then((res) => {
      setCTV(res);
    });
  };

  const payment = async () => {
    try {
      crxFetch.put(`${appConfig.orders}/${id}/admin/payment`).then((res) => {
        setStatus("CHO_GIAO_VIEC");
        alert("Thanh toán thành công.");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllAgent = async () => {
    try {
      crxFetch.get(`${appConfig.users}/agent`).then((res) => {
        setAgents(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateCTV = async () => {
    try {
      crxFetch.put(`${appConfig.orders}/${id}/assign-job?userId=${agent.id}`).then((res) => {
        setCTV(agent);
        setStatus("CHO_NHAN_VIEC");
        setIsUpdateCTV(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    setOpen(true);
  };

  const handleClickAgree = async () => {
    await cancelOrder();
    setOpen(false);
  };

  const cancelOrder = async () => {
    try {
      crxFetch.put(`${appConfig.orders}/${id}/cancel`).then((res) => {
        setStatus("HUY");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const confirmComplete = async () => {
    try {
      crxFetch.put(`${appConfig.orders}/${id}/confirm-done`).then((res) => {
        setStatus("XAC_NHAN_HOAN_THANH");
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInfoUser(userId);
    getAllAgent();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({ title: orderCode, back: "/order" })}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thông tin khách hàng")}
                {getItemUser({ title: "Họ tên khách hàng", value: customer?.fullName })}
                {getItemUser({ title: "Số điện thoại", value: customer?.phone })}
                {getItemUser({ title: "Địa chỉ", value: customer?.address, gridNumber: 4 })}
              </Grid>
            </Card>
          </Grid>

          {CTV.id && (
            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {titleItem("Thông tin CTV/Đại lý")}
                  {getItemUser({
                    title: "Trạng thái",
                    value: getItemStatus({ statusOption: statusOptionUser, value: CTV?.status }),
                  })}
                  {getItemUser({ title: "Họ tên", value: CTV?.fullName })}
                  {getItemUser({ title: "Số điện thoại", value: CTV?.phone })}
                  {getItemUser({ title: "Chiết khấu(%)", value: CTV?.discount })}
                  {getItemUser({ title: "Địa chỉ", value: CTV?.address, gridNumber: 4 })}
                </Grid>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thông tin đơn hàng")}
                <Grid item xs={12} sx={{ marginBottom: "-12px" }}>
                  <Grid container spacing={6} mt={-7}>
                    {getItemUser({
                      title: "Trạng thái",
                      value: getItemStatus({ statusOption: statusOptionOrder, value: status }),
                    })}

                    {status === "DOI_THANH_TOAN" && (
                      <Grid item xxl={3} xl={4}>
                        <Grid item xs={12} sx={{ marginTop: "-30px" }}>
                          <MDBox p={2} pb={2} mt={4}>
                            <MDButton
                              variant="contained"
                              color="error"
                              sx={{ marginTop: "20px" }}
                              onClick={async () => await payment()}
                            >
                              Thanh toán
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    )}

                    {status === "HOAN_THANH" && (
                      <Grid item xxl={3} xl={4}>
                        <Grid item xs={12} sx={{ marginTop: "-30px" }}>
                          <MDBox p={2} pb={2} mt={4}>
                            <MDButton
                              variant="contained"
                              color="error"
                              sx={{ marginTop: "20px" }}
                              onClick={async () => await confirmComplete()}
                            >
                              Xác nhận hoàn thành
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    )}

                    {isUpdateCTV && (
                      <Grid item xs={5}>
                        <Grid item xs={12} sx={{ marginTop: "18px" }}>
                          <MDBox p={2} mb={2} sx={{ display: "flex" }}>
                            {ItemAutocomplete({
                              options: agents.map((agent) => {
                                return {
                                  label: `${agent.fullName} - ${agent.phone} - ${agent.address}`,
                                  ...agent,
                                };
                              }),
                              setState: setAgent,
                              title: "Người nhận việc",
                              width: 500,
                            })}
                          </MDBox>
                        </Grid>
                      </Grid>
                    )}

                    {(status === "CHO_GIAO_VIEC" || status === "CHO_NHAN_VIEC") && (
                      <Grid item xxl={3} xl={4}>
                        <Grid item xs={12} sx={{ marginTop: "-24px", marginBottom: "-84px" }}>
                          <MDBox p={2} pb={0} mt={4}>
                            {isUpdateCTV ? (
                              <>
                                <MDButton
                                  variant="contained"
                                  color="error"
                                  sx={{
                                    marginTop: "20px",
                                    marginBottom: "60px",
                                    width: "100px",
                                    marginRight: "12px",
                                  }}
                                  onClick={async () => await updateCTV()}
                                >
                                  Lưu
                                </MDButton>

                                <MDButton
                                  variant="contained"
                                  color="error"
                                  sx={{ marginTop: "20px", marginBottom: "60px", width: "100px" }}
                                  onClick={async () => setIsUpdateCTV(false)}
                                >
                                  Cancel
                                </MDButton>
                              </>
                            ) : (
                              <MDButton
                                variant="contained"
                                color="error"
                                sx={{ marginTop: "20px" }}
                                onClick={() => setIsUpdateCTV(true)}
                              >
                                {CTV.id ? "Chỉnh sửa CTV/Đại lý" : "Giao việc"}
                              </MDButton>
                            )}
                          </MDBox>
                        </Grid>
                      </Grid>
                    )}

                    {status !== "HUY" && (
                      <Grid item xxl={2} xl={4}>
                        <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                          <MDBox p={2} pb={2} mt={4}>
                            <MDButton
                              variant="contained"
                              color="error"
                              sx={{ marginTop: "20px" }}
                              onClick={async () => await handleDelete()}
                            >
                              Hủy đơn hàng
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={6} mt={-7}>
                    {getItemUser({
                      title: "Tổng tiền",
                      value: order.realTotalPrincipal
                        ? `${order.realTotalPrincipal.toLocaleString(
                            "en-US"
                          )} (${order.totalPrincipal.toLocaleString("en-US")})`
                        : order.totalPrice?.toLocaleString("en-US"),
                    })}
                    {getItemUser({
                      title: "Phí",
                      value: order.totalFee?.toLocaleString("en-US"),
                    })}
                    {getItemUser({
                      title: "Thuế",
                      value: order.totalTax?.toLocaleString("en-US"),
                    })}
                    {getItemUser({
                      title: "Thành tiền",
                      value: order.realTotalPrincipal
                        ? `${order.realTotalPrincipal.toLocaleString(
                            "en-US"
                          )} (${order.totalPrincipal.toLocaleString("en-US")})`
                        : order.totalPrincipal?.toLocaleString("en-US"),
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <AlertDialogSlide
        open={open}
        setOpen={setOpen}
        handleClickAgree={handleClickAgree}
        title="Xóa đơn hàng"
        description="Bạn có chắc chắn muốn xóa không?"
      />
    </DashboardLayout>
  );
}

export default OrderDetailTables;
