import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { TittlePage } from "components/TitlePage";
import { itemAttribute, itemStatus } from "components/ItemAttribute";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getItemStatus } from "components/ButtonStatus";
import { useNavigate } from "react-router-dom";
import { statusOptionProduct } from "../../../config/configStatus";
import { AlertDialogSlide } from "components/PopUpNotification";

import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";

function ProductDetailTables() {
  const navigate = useNavigate();
  const crxFetch = useFetch();
  let { id } = useParams();

  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({});

  const getProductDetail = async () => {
    try {
      await crxFetch.get(`${appConfig.products}/${id}`).then((res) => {
        // if (res.data[0]) setProduct(res.data[0]);
        setProduct(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getItemProduct = ({ title, value }) => {
    return (
      <Grid item xs={2} md={4}>
        <Grid item xs={12}>
          <MDBox p={1} pl={2}>
            <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>{title}</MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "-24px" }}>
          <MDBox p={2} pb={2}>
            <MDTypography sx={{ fontSize: "14px", color: "#000000" }}>{value}</MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const handleClickAgree = async () => {
    try {
      await crxFetch.delete(`${appConfig.products}/${product.id}`).then((res) => {
        navigate("/product");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    setOpen(true);
  };

  const handleUpdate = () => {
    navigate(`/product/edit/${id}`);
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {product.code && (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            {TittlePage({
              title: product.name,
              back: "/product",
              create: true,
              titleCreate: "Chỉnh sửa",
              colorCreate: "light",
              handleSave: handleUpdate,
              isDelete: product.status === "ACTIVE" ? true : false,
              handleDelete,
            })}

            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {titleItem(`${product.name}`)}
                  {getItemProduct({ title: "Mã", value: product.code })}
                  {getItemProduct({ title: "Tên sản phẩm", value: product.name })}
                  {getItemProduct({
                    title: "Thời gian bảo hành",
                    value: product.warrantyTime,
                  })}
                  {getItemProduct({
                    title: "Đơn giá",
                    value: product.unitPrice.toLocaleString("en-US"),
                  })}
                  {getItemProduct({
                    title: "Rating",
                    value: product.rating,
                  })}
                  {getItemProduct({
                    title: "Trạng thái",
                    value: getItemStatus({
                      statusOption: statusOptionProduct,
                      value: product.status,
                    }),
                  })}
                </Grid>
              </Card>
            </Grid>

            {product?.attrSize?.length > 0 && (
              <Grid item xs={12}>
                <Card className="card_title_container">
                  <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                    {itemAttribute({
                      title: "Kích thước",
                      array: product?.attrSize,
                      backgroundColor: "#D31C23",
                      grid: 4,
                    })}
                  </Grid>
                </Card>
              </Grid>
            )}

            {product?.attrType?.length > 0 && (
              <Grid item xs={12}>
                <Card className="card_title_container">
                  <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                    {itemAttribute({
                      title: "Kiểu loại",
                      array: product?.attrType,
                      backgroundColor: "#FF7E55",
                    })}
                  </Grid>
                </Card>
              </Grid>
            )}

            {product?.attrOpenType?.length > 0 && (
              <Grid item xs={12}>
                <Card className="card_title_container">
                  <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                    {itemAttribute({
                      title: "Cách mở",
                      array: product?.attrOpenType,
                      backgroundColor: "#FF7E55",
                    })}
                  </Grid>
                </Card>
              </Grid>
            )}

            {product?.attrBrand?.length > 0 && (
              <Grid item xs={12}>
                <Card className="card_title_container">
                  <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                    {itemAttribute({
                      title: "Thương hiệu",
                      array: product?.attrBrand,
                      backgroundColor: "#FF7E55",
                    })}
                  </Grid>
                </Card>
              </Grid>
            )}

            {product?.attrInstall?.length > 0 && (
              <Grid item xs={12}>
                <Card className="card_title_container">
                  <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                    {itemAttribute({
                      title: "Cài đặt",
                      array: product?.attrInstall,
                      backgroundColor: "#FF7E55",
                    })}
                  </Grid>
                </Card>
              </Grid>
            )}

            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  <Grid item xs={12} className="title_background_container">
                    <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>
                      Hình ảnh
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: "4px", display: "flex", alignItems: "center" }}
                  >
                    {product.urlImages.length > 0 && (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {product.urlImages.map((image, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              width: "150px",
                              height: "150px",
                              marginRight: "8px",
                              marginBottom: "24px",
                            }}
                          >
                            <img
                              src={product.urlImages[index]}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover", borderRadius: "4px" }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {itemAttribute({
                    title: "Mô tả",
                    description: product?.description || "Không có dữ liệu",
                  })}
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {itemAttribute({
                    title: "Hướng dẫn lắp đặt",
                    link: product?.linkVideo || "Không có link mô tả",
                  })}
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <MDBox
                sx={{
                  cursor: "pointer",
                  textTransform: "none !important",
                  padding: "12px 24px",
                  backgroundColor: "rgb(29 119 255)",
                  borderRadius: "12px",
                  display: "inline-flex",
                  fontWeight: "300",
                }}
                component="label"
              >
                <Link
                  to={`/product/create/attribute/${product.id}`}
                  style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}
                >
                  Thêm thuộc tính của sản phẩm
                </Link>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <AlertDialogSlide
        open={open}
        setOpen={setOpen}
        handleClickAgree={handleClickAgree}
        title="Xóa sản phẩm"
        description="Bạn có chắc chắn muốn xóa không?"
      />
    </DashboardLayout>
  );
}

export default ProductDetailTables;
