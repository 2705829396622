import MDInput from "components/MDInput";

export const input = ({ index, handleChange, value, width }) => {
  return (
    <MDInput
      id="input_center"
      value={value}
      type="number"
      variant="standard"
      label=""
      sx={{ width: width }}
      onChange={(e) => handleChange(e.target.value, index)}
    />
  );
};
