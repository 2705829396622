import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import orderData from "layouts/order/orderData";
import MDButton from "components/MDButton";
import { statusOptionOrder } from "../../config/configStatus";
import { getItemSearch } from "components/Search/itemSearch";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import appConfig from "../../config/index";
import Pagination from "@mui/material/Pagination";

function OrderTables() {
  const navigate = useNavigate();

  const { columns, rows, searchOrders, pages, getOrders } = orderData();

  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearch = async (data) => {
    await searchOrders(data, 0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={6} sx={{ marginBottom: "-16px" }}>
                {getItemSearch({
                  title: "Trạng thái",
                  setState: setStatus,
                  type: "select",
                  optionSelect: statusOptionOrder,
                  valueState: status,
                })}
                {getItemSearch({ title: "CTV/Đại lý", setState: setName })}
                {getItemSearch({
                  title: "Giao việc từ ngày",
                  setState: setFromDate,
                  valueState: fromDate,
                  type: "date",
                })}
                {getItemSearch({
                  title: "Đến ngày",
                  setState: setEndDate,
                  valueState: endDate,
                  type: "date",
                })}
                {getItemSearch({
                  type: "button",
                  dataSearch: {
                    status,
                    name,
                    fromDate: fromDate ? new Date(fromDate).toLocaleDateString("es-CL") : "",
                    endDate: endDate ? new Date(endDate).toLocaleDateString("es-CL") : "",
                  },
                  handleSearch,
                })}
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "end", paddingLeft: "12px !important" }}
                >
                  {/* <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                    <MDBox pt={2} pb={4} sx={{ display: "flex" }}>
                      <MDButton
                        variant="outlined"
                        color="error"
                        sx={{
                          marginTop: "32px",
                          width: "150px",
                          color: "#D31C23",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate("/order/create")}
                      >
                        Tạo đơn hàng
                      </MDButton>
                    </MDBox>
                  </Grid> */}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{
                    defaultValue: appConfig.sizePage,
                    entries: [5, 10, 15, 20, 25, 50],
                  }}
                  showTotalEntries={false}
                  noEndBorder
                />
                {pages > 1 && (
                  <MDBox p={2}>
                    <Pagination
                      count={pages}
                      color="primary"
                      onChange={async (e, value) => {
                        if (!status && !name && !fromDate && !endDate)
                          return await getOrders(value - 1);
                        await searchOrders(
                          {
                            status,
                            name,
                            fromDate: fromDate
                              ? new Date(fromDate).toLocaleDateString("es-CL")
                              : "",
                            endDate: endDate ? new Date(endDate).toLocaleDateString("es-CL") : "",
                          },
                          value - 1
                        );
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default OrderTables;
