import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useFetch } from "../../hook/useFetch";
import { getUrlSearch } from "components/Search/itemSearch";
import appConfig from "../../config/index";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionAttribute } from "../../config/configStatus";

export default function attributeData() {
  const crxFetch = useFetch();

  const [attributes, setAttributes] = useState([]);
  const [pages, setPages] = useState(0);

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Mã thuộc tính": <MDBox ml={-1}>{item.code}</MDBox>,
      Tên: <MDBox ml={-1}>{item.name}</MDBox>,
      "Trạng thái": getItemStatus({ statusOption: statusOptionAttribute, value: item.status }),
      "Chỉnh sửa": (
        <Link ml={-1} to={`/attribute/edit?item=${JSON.stringify(item)}`}>
          <Icon sx={{ fontSize: "20px !important" }}>edit</Icon>
        </Link>
      ),
    };
  };

  const searchAttributes = (data, page) => {
    const urlSearch = getUrlSearch(data);
    try {
      crxFetch
        .get(`${appConfig.attributes}${urlSearch}size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setAttributes(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getAttributes = async (page) => {
    try {
      await crxFetch
        .get(`${appConfig.attributes}?size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setAttributes(rows);
          console.log(`searchAttributes ${attributes.length} - ${rows.length}`);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAttributes();
  }, []);

  return {
    columns: [
      { Header: "Stt", accessor: "Stt", width: "10%", align: "center" },
      { Header: "Tên", accessor: "Tên", width: "15%", align: "center" },
      { Header: "Mã thuộc tính", accessor: "Mã thuộc tính", width: "15%", align: "center" },
      { Header: "Trạng thái", accessor: "Trạng thái", width: "10%", align: "center" },
      { Header: "Chỉnh sửa", accessor: "Chỉnh sửa", width: "10%", align: "center" },
    ],
    rows: attributes,
    pages,
    searchAttributes,
    getAttributes,
  };
}
