import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
import appConfig from "../../../config/index";
import { useFetch } from "../../../hook/useFetch";
import { selectOption } from "components/SelectOption";
import { input } from "components/Input";

export default function createOrderData() {
  const crxFetch = useFetch();
  const [rows, setRows] = useState([]);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [totalPriceOrder, setTotalPriceOrder] = useState(0);

  const getAllProduct = async () => {
    try {
      const allProduct = await crxFetch.get(appConfig.products);
      setProducts(allProduct.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCustomer = async () => {
    try {
      const allCustomer = await crxFetch.get(appConfig.customers);
      setCustomers(allCustomer.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllUser = async () => {
    try {
      const allUser = await crxFetch.get(`${appConfig.users}?status=ACTIVE`);
      setUsers(allUser.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataEffect = async () => {
    await Promise.all([getAllProduct(), getAllUser(), getAllCustomer()]);
  };

  const handleChangeValueSelectOption = (value, index, indexAttr) => {
    console.log(value, index);
    let newDataProduct = dataProduct;
    newDataProduct[index].attr[indexAttr].value = value;
    const productOption = newDataProduct[index].attr[indexAttr].optionSelect.find(
      (item) => item.value === value
    );
    console.log(productOption);
    newDataProduct[index].attr[indexAttr].indexAttr = productOption.id;
    newDataProduct[index].attr[indexAttr].price = productOption.price;

    setDataProduct(newDataProduct);
    totalOrderCost(dataProduct);
    getCreateOrder(newDataProduct);
  };

  const handleChangeQuantity = (quantity, index) => {
    let newDataProduct = dataProduct;
    newDataProduct[index].quantity = +quantity;

    setDataProduct(newDataProduct);
    totalOrderCost(dataProduct);
    getCreateOrder(newDataProduct);
  };

  const handleDeleteProduct = (index) => {
    const newDataProduct = dataProduct.filter((item, i) => i !== index);
    setDataProduct(newDataProduct);
    totalOrderCost(dataProduct);
    getCreateOrder(newDataProduct);
  };

  const handleAddNewProduct = (product) => {
    const id = product.id;
    const productById = products.find((product) => product.id === id);

    const newProduct = {
      id: productById.id,
      name: productById.name,
      code: productById.code,
      attr: [
        {
          indexAttr: productById?.attrSize?.[0]?.id || 0,
          value: productById?.attrSize[0]?.value || "",
          optionSelect: productById?.attrSize?.map((item) => {
            return { title: item.value, value: item.value, id: item.id, price: item.price };
          }),
          price: productById?.attrSize[0]?.price,
        },
        {
          indexAttr: productById?.attrBrand?.[0]?.id || 0,
          brand: productById?.attrBrand[0]?.value || "",
          optionSelect: productById?.attrBrand?.map((item) => {
            return { title: item.value, value: item.value, id: item.id };
          }),
          price: productById?.attrBrand[0]?.price,
        },
        {
          indexAttr: productById?.attrOpenType?.[0]?.id || 0,
          openType: productById?.attrOpenType[0]?.value || "",
          optionSelect: productById?.attrOpenType?.map((item) => {
            return { title: item.value, value: item.value, id: item.id };
          }),
          price: productById?.attrOpenType[0]?.price,
        },
        {
          indexAttr: productById?.attrType?.[0]?.id || 0,
          type: productById?.attrType[0]?.value || "",
          optionSelect: productById?.attrType?.map((item) => {
            return { title: item.value, value: item.value, id: item.id };
          }),
          price: productById?.attrType[0]?.price,
        },
        {
          indexAttr: productById?.attrInstall?.[0]?.id || 0,
          install: productById?.attrInstall ? productById?.attrInstall[0]?.value : "",
          optionSelect:
            productById?.attrInstall?.map((item) => {
              return { title: item.value, value: item.value, id: item.id };
            }) || [],
          price: productById?.attrInstall ? productById?.attrInstall[0]?.price : "",
        },
      ],
      price: productById.unitPrice,
      quantity: 1,
    };

    setDataProduct([...dataProduct, newProduct]);
    totalOrderCost(dataProduct);
  };

  const totalProductCost = ({ price, attr, quantity }) => {
    let totalPriceAttr = 0;
    attr.map((item) => {
      if (item.price) totalPriceAttr += item.price;
    });
    return (price + totalPriceAttr) * quantity;
  };

  const totalOrderCost = (products) => {
    let totalPrice = 0;
    products.map((item) => {
      totalPrice += totalProductCost({
        price: item.price,
        quantity: item.quantity,
        attr: item.attr,
      });
    });
    setTotalPriceOrder(totalPrice);
  };

  const getCreateOrder = async (data) => {
    let valueRows = dataProduct;
    if (data) valueRows = data;
    const dataRows = valueRows.map((item, index) => {
      return {
        "Mã sản phẩm": <MDBox ml={-1}>{item.code}</MDBox>,
        "Tên sản phẩm": <MDBox ml={-1}>{item.name}</MDBox>,
        "Kích thước": item.attr[0].optionSelect.length
          ? selectOption({
              index,
              indexAttr: 0,
              value: item.attr[0].value,
              handleChange: handleChangeValueSelectOption,
              optionSelect: item.attr[0].optionSelect,
              minWidth: "100px",
            })
          : "",
        "Thương hiệu": item.attr[1].optionSelect.length
          ? selectOption({
              index,
              indexAttr: 1,
              value: item.attr[1].brand,
              handleChange: handleChangeValueSelectOption,
              optionSelect: item.attr[1].optionSelect,
              minWidth: "100px",
            })
          : "",
        "Cách mở": item.attr[2].optionSelect.length
          ? selectOption({
              index,
              indexAttr: 2,
              value: item.attr[2].openType,
              handleChange: handleChangeValueSelectOption,
              optionSelect: item.attr[2].optionSelect,
              minWidth: "100px",
            })
          : "",
        "Kiểu loại": item.attr[3].optionSelect.length
          ? selectOption({
              index,
              indexAttr: 3,
              value: item.attr[3].type,
              handleChange: handleChangeValueSelectOption,
              optionSelect: item.attr[3].optionSelect,
              minWidth: "100px",
            })
          : "",
        "Cách tải": item.attr[4].optionSelect.length
          ? selectOption({
              index,
              indexAttr: 4,
              value: item.attr[0].install,
              handleChange: handleChangeValueSelectOption,
              optionSelect: item.attr[4].optionSelect,
              minWidth: "100px",
            })
          : "",
        "Đơn giá": <MDBox ml={-1}>{item?.price?.toLocaleString("en-US") || ""}</MDBox>,
        "Số lượng": input({
          handleChange: handleChangeQuantity,
          index,
          value: item.quantity,
          width: "75px",
        }),
        "Thành tiền": (
          <MDBox ml={-1}>
            {totalProductCost({
              price: item?.price || 0,
              attr: item.attr,
              quantity: item.quantity,
            }).toLocaleString("en-US") || ""}
          </MDBox>
        ),
        Xóa: (
          <Icon
            sx={{
              width: "26px",
              height: "26px",
              backgroundColor: "#D9D9D9",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            fontSize="small"
            onClick={() => handleDeleteProduct(index)}
          >
            close
          </Icon>
        ),
      };
    });
    setRows(dataRows);
  };

  useEffect(() => {
    if (!products.length) {
      getDataEffect();
    } else {
      getCreateOrder();
      totalOrderCost(dataProduct);
    }
  }, [dataProduct.length]);

  return {
    columns: [
      { Header: "Mã sản phẩm", accessor: "Mã sản phẩm", width: "20%", align: "center" },
      { Header: "Tên sản phẩm", accessor: "Tên sản phẩm", width: "25%", align: "center" },
      { Header: "Kích thước", accessor: "Kích thước", width: "15%", align: "center" },
      { Header: "Thương hiệu", accessor: "Thương hiệu", width: "15%", align: "center" },
      { Header: "Cách mở", accessor: "Cách mở", width: "15%", align: "center" },
      { Header: "Kiểu loại", accessor: "Kiểu loại", width: "15%", align: "center" },
      { Header: "Cách tải", accessor: "Cách tải", width: "15%", align: "center" },
      { Header: "Đơn giá", accessor: "Đơn giá", width: "20%", align: "center" },
      { Header: "Số lượng", accessor: "Số lượng", width: "10%", align: "center" },
      { Header: "Thành tiền", accessor: "Thành tiền", width: "20%", align: "center" },
      { Header: "Xóa", accessor: "Xóa", width: "9%", align: "center" },
    ],
    rows,
    handleAddNewProduct,
    products,
    customers,
    users,
    dataProduct,
    totalPriceOrder,
  };
}
