import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import logoIcon from "assets/images/logos/logo-icon.png";
import backgroundLogin from "assets/images/logos/backgroundLogin.png";
import nameCompany from "assets/images/logos/nameCompany.svg";
import eye from "assets/images/logos/eye.svg";

import { useState } from "react";
import axios from "axios";
import appConfig from "../../../config/index";
import { useNavigate } from "react-router-dom";

function Basic() {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [password, setPassWord] = useState("");
  const [typePassword, setTypePassWord] = useState(true);

  const handleLogin = (data) => {
    axios
      .post(`${appConfig.baseURL}${appConfig.signIn}`, data)
      .then((res) => {
        localStorage.setItem("token_shand_viet_nam", JSON.stringify(res.data.accessToken));
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        xs={7}
        sx={{
          display: "flex",
          backgroundImage: `url(${backgroundLogin})`,
          backgroundSize: "cover",
        }}
      />
      <Grid item xs={5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Grid
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}
        >
          <MDBox
            component="img"
            src={logoIcon}
            alt="master card"
            sx={{ width: "61px", height: "61px" }}
          />

          <MDBox
            component="img"
            src={nameCompany}
            alt="master card"
            sx={{ width: "275px", height: "26px" }}
          />
        </Grid>
        <MDBox m={2} sx={{ display: "flex", justifyContent: "center" }}>
          <MDInput
            id="phone_login"
            type="phone"
            variant="standard"
            label="Email/Số điện thoại"
            fullWidth
            sx={{ color: "#C2C2C2", width: "80%" }}
            onChange={(e) => setPhone(e.target.value)}
          />
        </MDBox>
        <MDBox m={2} sx={{ display: "flex", justifyContent: "center", position: "relative" }}>
          <MDInput
            id="password_login"
            type={typePassword ? "password" : "text"}
            label="Mật khẩu"
            variant="standard"
            fullWidth
            sx={{ color: "#C2C2C2", width: "80%" }}
            onChange={(e) => setPassWord(e.target.value)}
          />
          {!typePassword && (
            <MDBox
              component="img"
              src={eye}
              alt="master card"
              sx={{
                width: "26px",
                height: "25px",
                position: "absolute",
                bottom: "2px",
                right: "20%",
                cursor: "pointer",
              }}
              onClick={() => setTypePassWord(true)}
            />
          )}
          {typePassword && (
            <Icon
              sx={{
                fontSize: "26px !important",
                position: "absolute",
                bottom: "2px",
                right: "20%",
                color: "#bcbcbc",
                cursor: "pointer",
              }}
              fontSize="small"
              onClick={() => setTypePassWord(false)}
            >
              visibility_off
            </Icon>
          )}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <MDButton
              color="error"
              sx={{
                color: "#D31C23",
                width: "76%",
                color: "#fff",
                fontSize: "18px",
                height: "54px",
                borderRadius: "28px",
              }}
              fullWidth
              onClick={(e) => handleLogin({ phone, password })}
            >
              Đăng nhập
            </MDButton>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default Basic;
