import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useFetch } from "../../hook/useFetch";
import { getUrlSearch } from "components/Search/itemSearch";
import appConfig from "../../config/index";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionCategory } from "../../config/configStatus";

export default function categoryData() {
  const crxFetch = useFetch();

  const [categories, setCategories] = useState([]);
  const [pages, setPages] = useState(0);

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Mã thể loại": <MDBox ml={-1}>{item.code}</MDBox>,
      Tên: <MDBox ml={-1}>{item.name}</MDBox>,
      "Trạng thái": getItemStatus({ statusOption: statusOptionCategory, value: item.status }),
      "Chỉnh sửa": (
        <Link ml={-1} to={`/category/edit?item=${JSON.stringify(item)}`}>
          <Icon sx={{ fontSize: "20px !important" }}>edit</Icon>
        </Link>
      ),
    };
  };

  const searchCategories = (data, page) => {
    const urlSearch = getUrlSearch(data);
    try {
      crxFetch
        .get(`${appConfig.productCategory}${urlSearch}size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setCategories(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getCategories = async (page) => {
    try {
      await crxFetch
        .get(`${appConfig.productCategory}?size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setCategories(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return {
    columns: [
      { Header: "Stt", accessor: "Stt", width: "10%", align: "center" },
      { Header: "Mã loại sản phẩm", accessor: "Mã thể loại", width: "15%", align: "center" },
      { Header: "Tên", accessor: "Tên", width: "15%", align: "center" },
      { Header: "Trạng thái", accessor: "Trạng thái", width: "10%", align: "center" },
      { Header: "Chỉnh sửa", accessor: "Chỉnh sửa", width: "10%", align: "center" },
    ],
    rows: categories,
    pages,
    searchCategories,
    getCategories,
  };
}
