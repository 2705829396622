import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import userData from "layouts/user/userData";
import { getItemSearch } from "components/Search/itemSearch";
import { useState } from "react";
import appConfig from "../../config/index";
import Pagination from "@mui/material/Pagination";

function UserTables() {
  const optionSelect = [
    { title: "Tên", value: "name" },
    { title: "Số điện thoại", value: "phone" },
  ];
  const { columns, rows, searchUsers, pages, getUsers } = userData();

  const [titleSearch, setTitleSearch] = useState(optionSelect[0].value);
  const [valueSearch, setValueSearch] = useState("");

  const handleSearch = async (data) => {
    await searchUsers(data, 0);
  };

  const getDataSearch = (title, value) => {
    let dataSearch = {};
    dataSearch[title] = value;
    return dataSearch;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={6} sx={{ marginBottom: "-16px" }}>
                {getItemSearch({
                  title: "Tiêu chí tìm kiếm",
                  setState: setTitleSearch,
                  type: "select",
                  optionSelect,
                  valueState: titleSearch,
                })}
                {titleSearch &&
                  getItemSearch({
                    title: optionSelect.find((item) => item.value === titleSearch)?.title,
                    setState: setValueSearch,
                  })}
                {getItemSearch({
                  type: "button",
                  dataSearch: getDataSearch(titleSearch, valueSearch),
                  handleSearch,
                })}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{
                    defaultValue: appConfig.sizePage,
                    entries: [5, 10, 15, 20, 25, 50],
                  }}
                  showTotalEntries={false}
                  noEndBuser
                />
                {pages > 1 && (
                  <MDBox p={2}>
                    <Pagination
                      count={pages}
                      color="primary"
                      onChange={async (e, value) => {
                        if (!valueSearch) return await getUsers(value - 1);
                        await searchUsers(getDataSearch(titleSearch, valueSearch), value - 1);
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserTables;
