import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import { useEffect, useState } from "react";
import { useFetch } from "../../../../hook/useFetch";
import appConfig from "../../../../config";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";

export default function createPricingMappingData({ id, classifications, handleDelete }) {
  const crxFetch = useFetch();

  const [pricingMappings, setPricingMappings] = useState([]);
  const [pricingMappingRows, setPricingMappingRows] = useState([]);
  const [pricingMappingColumns, setPricingMappingColumns] = useState([]);
  const [pages, setPages] = useState(0);
  const [itemId, setItemId] = useState(0);
  // const [attrOne, setAttrOne] = useState("Thuộc tính 1");
  // const [attrTwo, setAttrTwo] = useState("Thuộc tính 2");
  // const [attrThree, setAttrThree] = useState("Thuộc tính 3");
  // const [attrFour, setAttrFour] = useState("Thuộc tính 4");

  // Function to update attrOne
  // const setItemAttrOne = (value, id) => {
  //   // const updatedRows = pricingMappingRows.map(item => {
  //   //   if (item.id === id) {
  //   //     // Update the attrOne property with the new value
  //   //     return { ...item, attrOne: value };
  //   //   }
  //   //   return item;
  //   // });
  //   //
  //   // // Update the state with the new array of updated items
  //   // setPricingMappingRows(updatedRows);
  // };

  const updateAttrOne = (value, id) => {
    console.log(`updateAttrOne: ${pricingMappingRows.length} ${value} ${id}`);
  };

  const handleFileChange = async (e) => {
    // if (!e.target.files || !e.target.files[0]) return;
    // const file = event.target.files[0];
    // const validImageTypes = [`image/gif`, `image/jpeg`, `image/png`];
    // if (!validImageTypes.includes(file.type))
    //   return alert(
    //     `Please select an image in the correct format ['image/gif', 'image/jpeg', 'image/png'].`
    //   );
    // setImage(file);
    // const fileReader = new FileReader();
    // fileReader.readAsDataURL(file);
    //
    // fileReader.onload = function () {
    //   const url = fileReader.result;
    //   setUrlImage(url);
    // };
  };

  const handleDeleteAction = async (id) => {
    setItemId(id);
    handleDelete();
  };

  const handleEditAction = async (item) => {
    console.log(`handleEditAction ${item}`);
  };

  const deletePricingMapping = async () => {
    console.log(`deletePricingMapping ${itemId}`);
    try {
      await crxFetch.delete(`${appConfig.productMapping}/${itemId}`).then((res) => {
        getPriceMapping();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Thuộc tính 1": <MDBox ml={-1}>{item.attrOne}</MDBox>,
      "Thuộc tính 2": <MDBox ml={-1}>{item.attrTwo}</MDBox>,
      "Thuộc tính 3": <MDBox ml={-1}>{item.attrThree}</MDBox>,
      "Thuộc tính 4": <MDBox ml={-1}>{item.attrFour}</MDBox>,
      "Đơn giá": <MDBox ml={-1}>{item.price?.toLocaleString("en-US")}</MDBox>,
      "Đơn giá chiết khấu": <MDBox ml={-1}>{item.priceDiscount?.toLocaleString("en-US")}</MDBox>,
      Rating: <MDBox ml={-1}>{item.rating}</MDBox>,
      "Chỉnh sửa": (
        <div>
          <Link ml={-1} to={`/product/edit/product-mapping?item=${JSON.stringify(item)}`}>
            <Icon sx={{ fontSize: "20px !important" }}>edit</Icon>
          </Link>
          <Icon
            sx={{ fontSize: "20px !important", cursor: "pointer" }}
            onClick={() => handleDeleteAction(item.id)}
          >
            delete
          </Icon>
        </div>
      ),
      "Hình ảnh": (
        <>
          {!item.urlImage && (
            <MDButton
              variant="contained"
              sx={{
                fontSize: "12px",
                color: "#777",
                cursor: "pointer",
                textTransform: "none !important",
                padding: "0",
                fontWeight: "300",
              }}
              component="label"
            >
              <Icon sx={{ fontSize: "20px !important" }}>upload</Icon>
              Upload File
              <input type="file" onChange={handleFileChange()} hidden />
            </MDButton>
          )}
          {item.urlImage && <img src={item.urlImage} width="45" style={{ borderRadius: "4px" }} />}
        </>
      ),
    };
  };

  const getPriceMapping = async () => {
    try {
      crxFetch.get(appConfig.productMapping + "?productId=" + id).then((res) => {
        const rows = res.map((item) => {
          return setRows(item);
        });
        if (pages !== res.totalPage) setPages(res.totalPage);
        setPricingMappingRows(rows);
        console.log("getPriceMapping pricingMappingRows: ", pricingMappingRows);
        console.log("getPriceMapping rows: ", rows);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateColumnName = (value) => {
    console.log(`updateColumns: ${value.length}`);
    let columns = [];
    if (value.length == 1) {
      columns = [
        { Header: "Stt", accessor: "Stt", width: "10%", align: "center" },
        { Header: value[0].code, accessor: "Thuộc tính 1", width: "15%", align: "center" },
        { Header: "Đơn giá", accessor: "Đơn giá", width: "10%", align: "center", hidden: true },
        {
          Header: "Đơn giá chiết khấu",
          accessor: "Đơn giá chiết khấu",
          width: "10%",
          align: "center",
          hidden: true,
        },
        { Header: "Rating", accessor: "Rating", width: "10%", align: "center", hidden: true },
        { Header: "Hình ảnh", accessor: "Hình ảnh", width: "10%", align: "center" },
        { Header: "Cập nhật", accessor: "Chỉnh sửa", width: "10%", align: "center" },
      ];
    }

    if (value.length == 2) {
      columns = [
        { Header: "Stt", accessor: "Stt", width: "10%", align: "center" },
        { Header: value[0].code, accessor: "Thuộc tính 1", width: "15%", align: "center" },
        { Header: value[1].code, accessor: "Thuộc tính 2", width: "15%", align: "center" },
        { Header: "Đơn giá", accessor: "Đơn giá", width: "10%", align: "center", hidden: true },
        {
          Header: "Đơn giá chiết khấu",
          accessor: "Đơn giá chiết khấu",
          width: "10%",
          align: "center",
          hidden: true,
        },
        { Header: "Rating", accessor: "Rating", width: "10%", align: "center", hidden: true },
        { Header: "Hình ảnh", accessor: "Hình ảnh", width: "10%", align: "center" },
        { Header: "Cập nhật", accessor: "Chỉnh sửa", width: "10%", align: "center" },
      ];
    }

    if (value.length == 3) {
      columns = [
        { Header: "Stt", accessor: "Stt", width: "10%", align: "center" },
        { Header: value[0].code, accessor: "Thuộc tính 1", width: "15%", align: "center" },
        { Header: value[1].code, accessor: "Thuộc tính 2", width: "15%", align: "center" },
        { Header: value[2].code, accessor: "Thuộc tính 3", width: "10%", align: "center" },
        { Header: "Đơn giá", accessor: "Đơn giá", width: "10%", align: "center", hidden: true },
        {
          Header: "Đơn giá chiết khấu",
          accessor: "Đơn giá chiết khấu",
          width: "10%",
          align: "center",
          hidden: true,
        },
        { Header: "Rating", accessor: "Rating", width: "10%", align: "center", hidden: true },
        { Header: "Hình ảnh", accessor: "Hình ảnh", width: "10%", align: "center" },
        { Header: "Cập nhật", accessor: "Chỉnh sửa", width: "10%", align: "center" },
      ];
    }

    if (value.length == 4) {
      columns = [
        { Header: "Stt", accessor: "Stt", width: "10%", align: "center" },
        { Header: value[0].code, accessor: "Thuộc tính 1", width: "15%", align: "center" },
        { Header: value[1].code, accessor: "Thuộc tính 2", width: "15%", align: "center" },
        { Header: value[2].code, accessor: "Thuộc tính 3", width: "10%", align: "center" },
        {
          Header: value[3].code,
          accessor: "Thuộc tính 4",
          width: "10%",
          align: "center",
          hidden: true,
        },
        { Header: "Đơn giá", accessor: "Đơn giá", width: "10%", align: "center", hidden: true },
        {
          Header: "Đơn giá chiết khấu",
          accessor: "Đơn giá chiết khấu",
          width: "10%",
          align: "center",
          hidden: true,
        },
        { Header: "Rating", accessor: "Rating", width: "10%", align: "center", hidden: true },
        { Header: "Hình ảnh", accessor: "Hình ảnh", width: "10%", align: "center" },
        { Header: "Cập nhật", accessor: "Chỉnh sửa", width: "10%", align: "center" },
      ];
    }

    console.log(`AAAAAAAAAAA ${columns.length}`);
    setPricingMappingColumns(columns);
  };

  useEffect(() => {
    getPriceMapping();
    updateColumnName(classifications);
  }, []);

  return {
    pricingMappingColumns,
    pricingMappingRows,
    pricingMappings,
    setPricingMappings,
    updateColumnName,
    deletePricingMapping,
  };
}
