import MDBox from "components/MDBox";
import { getUrlSearch } from "components/Search/itemSearch";
import { useEffect, useState } from "react";
import { useFetch } from "../../hook/useFetch";
import appConfig from "../../config/index";

export default function customerData() {
  const crxFetch = useFetch();

  const [customers, setCustomers] = useState([]);
  const [pages, setPages] = useState(0);

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Họ tên": <MDBox ml={-1}>{item.fullName}</MDBox>,
      "Số điện thoại": <MDBox ml={-1}>{item.phone}</MDBox>,
      "Địa chỉ": <MDBox ml={-1}>{item.address}</MDBox>,
    };
  };

  const getCustomers = async (page) => {
    try {
      await crxFetch
        .get(`${appConfig.customers}?size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setCustomers(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const searchCustomers = (data, page) => {
    const urlSearch = getUrlSearch(data);
    try {
      crxFetch
        .get(`${appConfig.customers}${urlSearch}size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setCustomers(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCustomers(0);
  }, []);

  return {
    columns: [
      { Header: "Stt", accessor: "Stt", width: "10%", align: "center" },
      { Header: "Họ tên", accessor: "Họ tên", width: "25%", align: "center" },
      { Header: "Số điện thoại", accessor: "Số điện thoại", width: "25%", align: "center" },
      { Header: "Địa chỉ", accessor: "Địa chỉ", width: "25%", align: "center" },
    ],

    rows: customers,
    searchCustomers,
    pages,
    getCustomers,
  };
}
