import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFetch } from "../../hook/useFetch";
import appConfig from "../../config/index";
import { getUrlSearch } from "components/Search/itemSearch";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionCommission } from "../../config/configStatus";

export default function commissionData() {
  const crxFetch = useFetch();

  const [commissions, setCommissions] = useState([]);
  const [pages, setPages] = useState(0);

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Mã yêu cầu": (
        <Link
          ml={-1}
          to={`/commission/${item.id}?sdt=${item.customer?.phone}&agentId=${item.user?.id}`}
          style={{ color: "#2962ff" }}
        >
          {item.orderCode}
        </Link>
      ),
      "Tên CTV/Đại lý": <MDBox ml={-1}>{item.user?.fullName}</MDBox>,
      "Tên khách hàng": <MDBox ml={-1}>{item.customer?.fullName}</MDBox>,
      "SDT khách hàng": <MDBox ml={-1}>{item.customer?.phone}</MDBox>,
      "Ngày yêu cầu": <MDBox ml={-1}>{item.createDate}</MDBox>,
      "Ngày thanh toán": <MDBox ml={-1}>{item.updateDate}</MDBox>,
      "Tổng tiền đơn hàng": <MDBox ml={-1}>{item.principal?.toLocaleString("en-US")}</MDBox>,
      "Tổng tiền hoa hồng": <MDBox ml={-1}>{item.commission?.toLocaleString("en-US")}</MDBox>,
      "Tỉ lệ chiết khấu": (
        <MDBox sx={{ color: "red" }} ml={-1}>
          {item.commissionDiscount?.toLocaleString("en-US") || 0}%
        </MDBox>
      ),
      "Trạng thái": getItemStatus({
        statusOption: statusOptionCommission.map((item) => {
          return { ...item, value: item.title };
        }),
        value: item.commissionStatusOrder,
      }),
    };
  };

  const searchCommissions = (data, page) => {
    const urlSearch = getUrlSearch(data);
    try {
      crxFetch
        .get(`${appConfig.commissions}${urlSearch}size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setCommissions(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getCommissions = async (page) => {
    try {
      await crxFetch
        .get(`${appConfig.commissions}?size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          if (pages !== res.totalPage) setPages(res.totalPage);
          setCommissions(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCommissions(0);
  }, []);

  return {
    columns: [
      { Header: "Stt", accessor: "Stt", width: "1%", align: "center" },
      { Header: "Mã yêu cầu", accessor: "Mã yêu cầu", width: "5%", align: "center" },
      { Header: "Tên CTV/Đại lý", accessor: "Tên CTV/Đại lý", width: "10%", align: "center" },
      { Header: "Tên khách hàng", accessor: "Tên khách hàng", width: "10%", align: "center" },
      { Header: "SDT khách hàng", accessor: "SDT khách hàng", width: "10%", align: "center" },
      { Header: "Ngày yêu cầu", accessor: "Ngày yêu cầu", width: "10%", align: "center" },
      { Header: "Ngày thanh toán", accessor: "Ngày thanh toán", width: "10%", align: "center" },
      {
        Header: "Tổng tiền đơn hàng",
        accessor: "Tổng tiền đơn hàng",
        width: "10%",
        align: "center",
      },
      {
        Header: "Tổng tiền hoa hồng",
        accessor: "Tổng tiền hoa hồng",
        width: "10%",
        align: "center",
      },
      {
        Header: "Tỉ lệ chiết khấu",
        accessor: "Tỉ lệ chiết khấu",
        width: "10%",
        align: "center",
      },

      { Header: "Trạng thái", accessor: "Trạng thái", width: "10%", align: "center" },
    ],
    rows: commissions,
    searchCommissions,
    pages,
    getCommissions,
  };
}
