import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getItemSearch } from "components/Search/itemSearch";
import { statusOptionCategory } from "../../../config/configStatus";
import { TittlePage } from "components/TitlePage";
import { useNavigate, useLocation } from "react-router-dom";
import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";
import { useState } from "react";

function EditCategory() {
  const navigate = useNavigate();
  const crxFetch = useFetch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemJson = searchParams.get("item");
  const category = itemJson !== "undefined" ? JSON.parse(itemJson) : {};

  const indexStatusOptionCategory = statusOptionCategory.map((item, index) => {
    return { ...item, value: index };
  });

  const [code, setCode] = useState(category?.code);
  const [name, setName] = useState(category?.name);
  const [status, setStatus] = useState(
    statusOptionCategory.findIndex((item) => item.value === category?.status)
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const updateCategory = async (data) => {
    try {
      await crxFetch.put(appConfig.createCategory, data).then((res) => {
        navigate("/category");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    setIsDisabled(true);
    await updateCategory({ id: category.id, name, code, status });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({
            title: "Chỉnh sửa sản phẩm",
            back: "/category",
            create: true,
            handleSave,
            isDisabled,
          })}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Các mục loại sản phẩm")}
                {getItemSearch({ title: "Mã loại sản phẩm", setState: setCode, valueState: code })}
                {getItemSearch({ title: "Tên loại sản phẩm", setState: setName, valueState: name })}
                {getItemSearch({
                  title: "Trạng thái",
                  setState: setStatus,
                  type: "select",
                  optionSelect: indexStatusOptionCategory,
                  valueState: status,
                })}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditCategory;
