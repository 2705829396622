import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { getItemSearch } from "components/Search/itemSearch";
import { TittlePage } from "components/TitlePage";
import { ItemAutocomplete } from "components/AutoComplete";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../../../hook/useFetch";
import appConfig from "../../../../../config/index";

function EditProductMapping() {
  const navigate = useNavigate();
  const crxFetch = useFetch();
  const searchParams = new URLSearchParams(location.search);
  const itemJson = searchParams.get("item");
  const item = itemJson !== "undefined" ? JSON.parse(itemJson) : {};
  const [productId, setProductId] = useState(0);
  const [id, setId] = useState(0);
  const [attrs, setAttrs] = useState([]);
  const [titleAttrOne, setTitleAttrOne] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState();
  const [titleAttrTwo, setTitleAttrTwo] = useState();
  const [titleAttrThree, setTitleAttrThree] = useState();
  const [titleAttrFour, setTitleAttrFour] = useState();
  const [attrOneValue, setAttrOneValue] = useState(null);
  const [attrTwoValue, setAttrTwoValue] = useState(null);
  const [attrThreeValue, setAttrThreeValue] = useState(null);
  const [attrFourValue, setAttrFourValue] = useState(null);

  const [image, setImage] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [urlImages, setUrlImages] = useState([]);
  const [price, setPrice] = useState(0);
  const [priceDiscount, setPriceDiscount] = useState(0);
  const [rating, setRating] = useState(0);

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const handlePriceInputChange = (value) => {
    const inputValue = parseFloat(value.replace(/[^0-9.]/g, "")); // Remove non-numeric characters
    setPrice(isNaN(inputValue) ? "" : inputValue);
  };

  const handlePriceDiscountInputChange = (value) => {
    const inputValue = parseFloat(value.replace(/[^0-9.]/g, "")); // Remove non-numeric characters
    setPriceDiscount(isNaN(inputValue) ? "" : inputValue);
  };

  const handleImageSelection = (index) => {
    setSelectedImageIndex(index);
    setUrlImage(urlImages[index]);
  };

  const updateProductMapping = async (data) => {
    try {
      await crxFetch
        .put(appConfig.productMapping, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          navigate(`/product/create/attribute/` + item.productId);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addProductMapping = async (data) => {
    try {
      await crxFetch
        .post(appConfig.productMapping, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          navigate(`/product/create/attribute/` + item.productId);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getProductAttribute = async () => {
    try {
      crxFetch.get(appConfig.productAttributes + "?productId=" + item.productId).then((res) => {
        setAttrs(res);
        updateTitle(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getProduct = async () => {
    try {
      crxFetch.get(appConfig.products + "/" + item.productId).then((res) => {
        setUrlImages(res.urlImages);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    const dataProduct = {
      productId: item.productId,
    };
    if (item.id) dataProduct.id = item.id;
    if (priceDiscount) dataProduct.priceDiscount = priceDiscount;
    if (price) dataProduct.price = price;
    if (attrOneValue) dataProduct.attrOne = attrOneValue;
    if (attrTwoValue) dataProduct.attrTwo = attrTwoValue;
    if (attrThreeValue) dataProduct.attrThree = attrThreeValue;
    if (attrFourValue) dataProduct.attrFour = attrFourValue;
    if (rating) dataProduct.rating = rating;
    if (selectedImageIndex >= 0) dataProduct.urlImage = urlImages[selectedImageIndex];

    let bodyFormData = new FormData();
    const keyObject = Object.keys(dataProduct);
    keyObject.forEach((key) => {
      if (key === "image" && !dataProduct.image?.name) {
      } else {
        bodyFormData.append(key, dataProduct[key]);
      }
    });
    if (item.id) {
      await updateProductMapping(bodyFormData);
    } else {
      await addProductMapping(bodyFormData);
    }
  };

  const initData = () => {
    setAttrOneValue(item.attrOne);
    setAttrTwoValue(item.attrTwo);
    setAttrThreeValue(item.attrThree);
    setAttrFourValue(item.attrFour);
    setPrice(item.price);
    setPriceDiscount(item.priceDiscount);
    setRating(item.rating);
  };

  const updateTitle = (value) => {
    console.log(`updateTitle ${value.length}`);
    if (value.length >= 1) {
      setTitleAttrOne(value[0].code);
    }
    if (value.length >= 2) {
      setTitleAttrTwo(value[1].code);
    }
    if (value.length >= 3) {
      setTitleAttrThree(value[2].code);
    }
    if (value.length >= 4) {
      setTitleAttrFour(value[3].code);
    }
  };

  useEffect(() => {
    getProductAttribute();
    getProduct();
    initData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({
            title: "Chỉnh sửa mapping sản phẩm",
            back: `/product/create/attribute/${item.productId}`,
            create: true,
            handleSave,
          })}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thông tin sản phẩm")}
                {titleAttrOne &&
                  getItemSearch({
                    title: titleAttrOne,
                    setState: setAttrOneValue,
                    valueState: attrOneValue,
                  })}
                {titleAttrTwo &&
                  getItemSearch({
                    title: titleAttrTwo,
                    setState: setAttrTwoValue,
                    valueState: attrTwoValue,
                  })}
                {titleAttrThree &&
                  getItemSearch({
                    title: titleAttrThree,
                    setState: setAttrThreeValue,
                    valueState: attrThreeValue,
                  })}
                {titleAttrFour &&
                  getItemSearch({
                    title: titleAttrFour,
                    setState: setAttrFourValue,
                    valueState: attrFourValue,
                  })}
                <Grid item xs={12}>
                  <Grid container spacing={6}>
                    {getItemSearch({
                      title: "Đơn giá",
                      setState: handlePriceInputChange,
                      valueState: price?.toLocaleString("en-US"),
                      gridNumber: 2,
                    })}
                    {getItemSearch({
                      title: "Đơn giá chiết khấu",
                      setState: handlePriceDiscountInputChange,
                      valueState: priceDiscount?.toLocaleString("en-US"),
                      gridNumber: 2,
                    })}
                    {getItemSearch({
                      title: "Rating",
                      setState: setRating,
                      valueState: rating,
                    })}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} mb={2} ml={2}>
                      <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
                        Hình ảnh sản phẩm
                      </MDTypography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ marginTop: "4px", display: "flex", alignItems: "center" }}
                    >
                      {urlImages.length > 0 && (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {urlImages.map((urlImage, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                width: "150px",
                                height: "150px",
                                marginRight: "8px",
                                marginBottom: "24px",
                                border:
                                  selectedImageIndex === index
                                    ? "2px solid #D31C23"
                                    : "2px solid transparent", // Border style based on selection
                                borderRadius: "4px",
                                cursor: "pointer",
                                overflow: "hidden",
                              }}
                              onClick={() => handleImageSelection(index)}
                            >
                              <img
                                src={urlImage}
                                width="100%"
                                height="100%"
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditProductMapping;
