export const statusOptionOrder = [
  { title: "Đợi thanh toán", value: "DOI_THANH_TOAN", backgroundColor: "#6c757d" },
  { title: "Chờ nhận hàng", value: "CHO_NHAN_HANG", backgroundColor: "#FFE897" },
  { title: "Đã hẹn khách", value: "DA_HEN_KHACH", backgroundColor: "#FFB8EB" },
  { title: "Hoàn thành", value: "HOAN_THANH", backgroundColor: "#BCC58A" },
  { title: "Đã xác nhận hoàn thành", value: "XAC_NHAN_HOAN_THANH", backgroundColor: "green" },
  { title: "Chờ giao việc", value: "CHO_GIAO_VIEC", backgroundColor: "#FF794F" },
  { title: "Chờ nhận việc", value: "CHO_NHAN_VIEC", backgroundColor: "#B1D0FF" },
  { title: "Chờ lắp đặt", value: "CHO_LAP_DAT", backgroundColor: "#B1D0FF" },
  { title: "Hủy", value: "HUY", backgroundColor: "red" },
];

export const statusOptionProduct = [
  { title: "Đang kinh doanh", value: "ACTIVE", backgroundColor: "#32BF40" },
  { title: "Ngừng kinh doanh", value: "INACTIVE", backgroundColor: "#D31C23" },
  { title: "Hết hàng", value: "OUT_OF_STOCK", backgroundColor: "#FFE897" },
  { title: "Đã bị xoá", value: "DELETE", backgroundColor: "#FFE897" },
];

export const statusOptionUser = [
  { title: "Đang hoạt động", value: "ACTIVE", backgroundColor: "#2BD31C" },
  { title: "Không hoạt động", value: "INACTIVE", backgroundColor: "#c0c4c9" },
  { title: "Đang chờ duyệt", value: "WAITING", backgroundColor: "#D31C23" },
  { title: "CANCEL", value: "CANCEL", backgroundColor: "#D31C23" },
];

export const statusOptionCategory = [
  { title: "Không hoạt động", value: "INACTIVE", backgroundColor: "#c0c4c9" },
  { title: "Đang hoạt động", value: "ACTIVE", backgroundColor: "#2BD31C" },
];

export const statusOptionAttribute = [
  { title: "Không hoạt động", value: "INACTIVE", backgroundColor: "#c0c4c9" },
  { title: "Đang hoạt động", value: "ACTIVE", backgroundColor: "#2BD31C" },
];

export const statusOptionCommission = [
  { title: "Chưa thanh toán", value: "CHUA_THANH_TOAN", backgroundColor: "#6c757d" },
  { title: "Chờ thanh toán", value: "CHO_THANH_TOAN", backgroundColor: "#FFE897" },
  { title: "Đã thanh toán", value: "DA_THANH_TOAN", backgroundColor: "#2BD31C" },
  { title: "Hủy", value: "HUY", backgroundColor: "#B1D0FF" },
];

export const titleHeaderRouter = [
  { title: "Quản lý đơn hàng", value: "order" },
  { title: "Quản lý sản phẩm", value: "product" },
  { title: "CTV/Đại lý", value: "user" },
  { title: "Quản lý khách hàng", value: "customer" },
  { title: "Quản lý hoa hồng", value: "commission" },
];

export const statusOptionClassification = [
  { title: "Cách mở", value: "OPEN_TYPE" },
  { title: "Kiểu loại", value: "TYPE" },
  { title: "Kích thước", value: "SIZE" },
  { title: "Thương hiệu", value: "BRAND" },
  { title: "Cài đặt", value: "INSTALL" },
];
