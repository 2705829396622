import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import productData from "layouts/product/productData";
import { getItemSearch } from "components/Search/itemSearch";
import { statusOptionProduct } from "../../config/configStatus";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import appConfig from "../../config/index";
import { useFetch } from "../../hook/useFetch";
import { ItemAutocomplete } from "components/AutoComplete";
import Pagination from "@mui/material/Pagination";

function ProductTables() {
  const crxFetch = useFetch();
  const navigate = useNavigate();
  const { columns, rows, searchProduces, pages, getProducts } = productData();

  const [productCode, setProductCode] = useState("");
  const [productName, setProductName] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  const handleSearch = async (data) => {
    await searchProduces(data, 0);
  };

  const getCategory = async () => {
    try {
      crxFetch.get(appConfig.productCategory).then((res) => {
        setCategories(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={6} sx={{ marginBottom: "-16px" }}>
                {getItemSearch({
                  title: "Mã sản phẩm",
                  setState: setProductCode,
                })}
                {getItemSearch({
                  title: "Tên sản phẩm",
                  setState: setProductName,
                })}
                {getItemSearch({
                  title: "Trạng thái",
                  setState: setStatus,
                  type: "select",
                  optionSelect: statusOptionProduct,
                  valueState: status,
                })}
                <Grid item xxl={2} xl={4}>
                  <Grid item xs={12} mb={2} ml={2}>
                    <MDTypography sx={{ fontSize: "12px", color: "#797979", marginTop: "7px" }}>
                      Chọn loại
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                    <MDBox
                      p={2}
                      pb={4}
                      sx={{ display: "flex" }}
                      className="input_autocomplete_search"
                    >
                      {ItemAutocomplete({
                        options: categories.map((item) => {
                          return {
                            label: item.name,
                            ...item,
                          };
                        }),
                        setState: setCategory,
                        width: 200,
                      })}
                    </MDBox>
                  </Grid>
                </Grid>
                {getItemSearch({
                  type: "button",
                  dataSearch: { productCode, productName, status, categoryId: category?.id || "" },
                  handleSearch,
                })}
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "end", paddingLeft: "12px !important" }}
                >
                  <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                    <MDBox pt={2} pb={4} sx={{ display: "flex" }}>
                      <MDButton
                        variant="outlined"
                        color="error"
                        sx={{
                          marginTop: "32px",
                          width: "170px",
                          color: "#D31C23",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate("/product/create")}
                      >
                        Thêm sản phẩm
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{
                    defaultValue: appConfig.sizePage,
                    entries: [5, 10, 15, 20, 25, 50],
                  }}
                  showTotalEntries={false}
                  noEndBproduct
                />
                {pages > 1 && (
                  <MDBox p={2}>
                    <Pagination
                      count={pages}
                      color="primary"
                      onChange={async (e, value) => {
                        if (!productCode && !productName && !status && !category)
                          return await getProducts(value - 1);
                        await searchProduces(
                          { productCode, productName, status, categoryId: category?.id || "" },
                          value - 1
                        );
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ProductTables;
