import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export const itemAttribute = ({ title, array, backgroundColor, description, link }) => {
  let arrayData = array || [];
  return (
    <>
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "-12px", marginLeft: "12px" }}>
        <Grid container xs={12}>
          {link && (
            <MDBox component="a" href={link} p={1} pb={2}>
              <MDTypography sx={{ color: "#1E78FF", fontSize: "14px", marginBottom: "12px" }}>
                {link.indexOf("http") > -1 ? link : "Không có link mô tả"}
              </MDTypography>
              {link.indexOf("http") > -1 && (
                <iframe
                  width="493"
                  height="277"
                  src={link ? link : "https://www.youtube.com/embed/tgbNymZ7vqY"}
                  style={{ border: "none" }}
                ></iframe>
              )}
            </MDBox>
          )}
          {description && (
            <MDBox p={1} pb={2}>
              <Box sx={{ fontSize: "14px", color: "#000000" }}>{description}</Box>
            </MDBox>
          )}
          {arrayData.map((item, index) => (
            <Grid item key={index}>
              <MDBox p={1} pb={2}>
                <Box>{itemStatus(item.value, backgroundColor)}</Box>
                {item.price > 0 && (
                  <Box
                    sx={{ fontSize: "12px", color: "green", padding: "4px 12px" }}
                  >{`+${item.price.toLocaleString("en-US")}`}</Box>
                )}
              </MDBox>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export const itemStatus = (value, backgroundColor, padding) => {
  return (
    <MDBox
      ml={-1}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor,
        borderRadius: "10px",
        color: "#fff",
        padding: padding || "6px 9px",
        fontSize: "14px",
        marginLeft: "0px",
      }}
    >
      {value}
    </MDBox>
  );
};
