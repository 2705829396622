import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
// import { statusOptionClassification } from "../../../../config/configStatus";
import { useEffect, useState } from "react";

export default function createAttributeData() {
  const [rows, setRows] = useState([]);
  const [classifications, setClassifications] = useState([]);

  const addNewClassification = (value) => {
    if (classifications.length < 4) {
      let itemExist = false;
      classifications.forEach((item) => {
        if (item.code == value.code) {
          itemExist = true;
        }
      });
      if (!itemExist) {
        const newClassification = {
          ...value,
          arrayAttr: [],
        };
        setClassifications([...classifications, newClassification]);
      }
    }
  };

  const deleteClassification = (index) => {
    const newClassifications = classifications.filter((item, i) => i !== index);
    setClassifications(newClassifications);
  };

  const addItemAttr = (value, index) => {
    let newClassifications = classifications;
    newClassifications[index].arrayAttr.push({ value, price: 0 });
    setClassifications(newClassifications);
    getCreateOrder(newClassifications);
  };

  const handleChangePrice = (value, index, indexAttr) => {
    let newClassifications = classifications;
    newClassifications[index].arrayAttr[indexAttr].price = value;
    setClassifications(newClassifications);
    getCreateOrder(newClassifications);
  };

  const handleChangeInstallationPrice = (value, index, indexAttr) => {
    let newClassifications = classifications;
    newClassifications[index].arrayAttr[indexAttr].installationPrice = value;
    setClassifications(newClassifications);
    getCreateOrder(newClassifications);
  };

  const appliesToAll = ({ newPrice }) => {
    let newClassifications = classifications.map((item) => {
      return {
        ...item,
        arrayAttr: item.arrayAttr.map((attr) => {
          return {
            ...attr,
            price: newPrice,
          };
        }),
      };
    });
    setClassifications(newClassifications);
    getCreateOrder(newClassifications);
  };

  const getCreateOrder = async (data) => {
    const dataRows = data.map((item, index) => {
      return {
        "Phân loại": <MDBox ml={-1}>{item.title}</MDBox>,
        "Thuộc tính": item.arrayAttr.map((attr, indexAttr) => (
          <MDBox key={indexAttr} ml={-1} p={1}>
            {attr.value}
          </MDBox>
        )),
        Giá: item.arrayAttr.map((attr, indexAttr) => (
          <MDBox key={indexAttr} ml={-1} p={1}>
            <MDInput
              id="input_center"
              value={attr.price}
              type="number"
              variant="standard"
              label=""
              onChange={(e) => handleChangePrice(e.target.value, index, indexAttr)}
            />
          </MDBox>
        )),
      };
    });
    setRows(dataRows);
  };

  useEffect(() => {
    getCreateOrder(classifications);
  }, []);

  return {
    columns: [],
    rows,
    classifications,
    addNewClassification,
    addItemAttr,
    setClassifications,
    getCreateOrder,
    deleteClassification,
    appliesToAll,
  };
}
