import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import { itemStatus } from "components/ItemAttribute";

export const classificationItem = ({
  index,
  classifications,
  setClassifications,
  addItemAttr,
  getCreateOrder,
}) => {
  const changeName = (value) => {
    let newClassifications = classifications;
    newClassifications[index].value = value;
    setClassifications(newClassifications);
    getCreateOrder(newClassifications);
  };

  const addType = () => {
    const elementStyle = document.getElementById(`inputType${index}`);
    addItemAttr(elementStyle.value, index);
    elementStyle.value = "";
  };

  const deleteItemAttr = (indexAttr) => {
    let newClassifications = classifications;
    newClassifications[index].arrayAttr = classifications[index].arrayAttr.filter(
      (item, i) => i !== indexAttr
    );
    setClassifications(newClassifications);
    getCreateOrder(newClassifications);
  };

  return (
    <>
      {classifications[index].name && (
        <Grid item xs={12} sx={{ marginTop: "4px" }}>
          <MDBox p={2} pb={4}>
            <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
              {classifications[index].name} - {classifications[index].code}
            </MDTypography>
          </MDBox>
        </Grid>
      )}
      {!classifications[index].name && (
        <Grid item xs={12} sx={{ marginTop: "4px" }}>
          <MDBox p={2} pb={4}>
            <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
              {classifications[index].code}
            </MDTypography>
          </MDBox>
        </Grid>
      )}
    </>
  );
};
