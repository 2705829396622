import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { getItemSearch } from "components/Search/itemSearch";
import { statusOptionProduct } from "../../../config/configStatus";
import { TittlePage } from "components/TitlePage";
import { ItemAutocomplete } from "components/AutoComplete";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";
import ClearIcon from "@mui/icons-material/Clear";

function CreateProduct() {
  const navigate = useNavigate();
  const crxFetch = useFetch();

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [images, setImages] = useState([]);
  const [urlImages, setUrlImages] = useState([]);
  const [linkVideo, setLinkVideo] = useState("");
  const [warrantyTime, setWarrantyTime] = useState(0);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [fee, setFee] = useState(0);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [status, setStatus] = useState(statusOptionProduct[0].value);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const handleFileChange = async (e) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const newImages = [...images];
    const newUrlImages = [...urlImages];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

      if (!validImageTypes.includes(file.type)) {
        return alert(
          `Please select images in the correct format ['image/gif', 'image/jpeg', 'image/png'].`
        );
      }

      newImages.push(file);

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = function () {
        const url = fileReader.result;
        newUrlImages.push(url);
      };
    }

    setImages(newImages);
    setUrlImages(newUrlImages);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    const newUrlImages = [...urlImages];

    newImages.splice(index, 1);
    newUrlImages.splice(index, 1);

    setImages(newImages);
    setUrlImages(newUrlImages);
  };

  const getCategory = async () => {
    try {
      crxFetch.get(appConfig.productCategory).then((res) => {
        setCategories(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createProduct = async (data) => {
    try {
      await crxFetch
        .post(appConfig.products, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          navigate(`/product/create/attribute/${res.id}`);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    const dataProduct = {
      code,
      name,
      images, // Giả sử images là một mảng chứa các đường dẫn hình ảnh
      linkVideo,
      warrantyTime: +warrantyTime,
      description,
      unitPrice: +unitPrice,
      originalPrice: +originalPrice,
      fee: +fee,
      tax: +tax,
      discount: +discount,
      status,
      categoryId: +category.id,
      rating: +rating,
    };

    console.log(dataProduct);

    const keyObject = Object.keys(dataProduct);
    let textInvalid = "";
    keyObject.map((key) => {
      if (key === "images") {
        // Kiểm tra nếu trường là images thì xử lý riêng
        if (!Array.isArray(dataProduct[key]) || dataProduct[key].length === 0) {
          textInvalid += key + ", ";
        }
      } else if (dataProduct[key] === "") {
        textInvalid += key + ", ";
      }
    });

    if (textInvalid) {
      textInvalid = textInvalid.slice(0, textInvalid.length - 2);
      return alert(`${textInvalid} is invalid`);
    }

    let bodyFormData = new FormData();
    keyObject.forEach((key) => {
      if (key === "images") {
        // Xử lý riêng cho trường images
        dataProduct[key].forEach((image, index) => {
          bodyFormData.append(`${key}`, image);
        });
      } else {
        bodyFormData.append(key, dataProduct[key]);
      }
    });

    await createProduct(bodyFormData);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({ title: "Thêm sản phẩm", back: "/product", create: true, handleSave })}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thông tin sản phẩm")}
                {getItemSearch({ title: "Mã sản phẩm", setState: setCode })}
                {getItemSearch({ title: "Tên sản phẩm", setState: setName })}
                {getItemSearch({
                  type: "number",
                  title: "Thời gian bảo hành(Tháng)",
                  setState: setWarrantyTime,
                })}
                {getItemSearch({
                  title: "Trạng thái",
                  setState: setStatus,
                  type: "select",
                  optionSelect: statusOptionProduct,
                  valueState: status,
                })}
                <Grid item xs={12}>
                  <Grid container spacing={6}>
                    {getItemSearch({
                      title: "Đơn giá bán",
                      setState: setUnitPrice,
                      type: "number",
                    })}
                    {getItemSearch({
                      title: "Đơn giá gốc",
                      setState: setOriginalPrice,
                      type: "number",
                    })}
                    {getItemSearch({
                      title: "Phí",
                      setState: setFee,
                      valueState: fee,
                      type: "number",
                      step: 0.1,
                    })}
                    {getItemSearch({
                      title: "Thuế",
                      setState: setTax,
                      valueState: tax,
                      type: "number",
                      step: 0.1,
                    })}
                    {getItemSearch({
                      title: "Chiết khấu",
                      setState: setDiscount,
                      valueState: discount,
                      type: "number",
                      step: 0.1,
                    })}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={6}>
                    {getItemSearch({
                      title: "Rating",
                      setState: setRating,
                    })}

                    {getItemSearch({
                      title: "Link video mô tả sản phẩm",
                      setState: setLinkVideo,
                      gridNumber: 4,
                    })}

                    <Grid item xs={3}>
                      <Grid item xs={12} mb={2} ml={2}>
                        <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
                          Chọn loại
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: "-24px" }}>
                        <MDBox p={2} pb={4} sx={{ display: "flex" }}>
                          {ItemAutocomplete({
                            options: categories.map((item) => {
                              return {
                                label: item.name,
                                ...item,
                              };
                            }),
                            setState: setCategory,
                            width: 300,
                          })}
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} mb={2} ml={2}>
                      <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>
                        Hình ảnh sản phẩm
                      </MDTypography>
                      <MDButton
                        variant="contained"
                        sx={{
                          fontSize: "12px",
                          color: "#777",
                          cursor: "pointer",
                          textTransform: "none !important",
                          padding: "0",
                          fontWeight: "300",
                          marginRight: "8px",
                        }}
                        component="label"
                      >
                        <Icon sx={{ fontSize: "20px !important" }}>upload</Icon>
                        Upload ảnh
                        <input type="file" onChange={handleFileChange} multiple hidden />
                      </MDButton>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ marginTop: "4px", display: "flex", alignItems: "center" }}
                    >
                      {images.length > 0 && (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {images.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                width: "200px",
                                height: "200px",
                                marginRight: "8px",
                                marginBottom: "8px",
                              }}
                            >
                              <img
                                src={urlImages[index]}
                                width="100%"
                                height="100%"
                                style={{ objectFit: "cover", borderRadius: "4px" }}
                              />
                              <MDButton
                                variant="contained"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  color: "red",
                                  backgroundColor: "transparent", // Set background to transparent
                                  cursor: "pointer",
                                  textTransform: "none !important",
                                  padding: "0",
                                  fontWeight: "normal",
                                }}
                                onClick={() => handleDeleteImage(index)}
                              >
                                <ClearIcon sx={{ fontSize: "20px !important" }} />
                              </MDButton>
                            </div>
                          ))}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={6} sx={{ display: "block" }} mt={-8}>
                    {getItemSearch({
                      title: "Mô tả",
                      setState: setDescription,
                      type: "textarea",
                      valueStatus: description,
                      gridNumber: 9,
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateProduct;
