import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { getItemSearch } from "components/Search/itemSearch";
import customerData from "layouts/customer/customerData";
import { useState } from "react";
import appConfig from "../../config/index";
import Pagination from "@mui/material/Pagination";

function CustomerTables() {
  const { columns, rows, searchCustomers, pages, getCustomers } = customerData();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const handleSearch = async (data) => {
    await searchCustomers(data, 0);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={6} sx={{ marginBottom: "-16px" }}>
                {getItemSearch({ title: "Tên", setState: setName })}
                {getItemSearch({ title: "số điện thoại", setState: setPhone })}
                {getItemSearch({
                  type: "button",
                  dataSearch: {
                    name,
                    phone,
                  },
                  handleSearch,
                })}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{
                    defaultValue: appConfig.sizePage,
                    entries: [5, 10, 15, 20, 25, 50],
                  }}
                  showTotalEntries={false}
                  noEndBcustomer
                />
                {pages > 1 && (
                  <MDBox p={2}>
                    <Pagination
                      count={pages}
                      color="primary"
                      onChange={async (e, value) => {
                        if (!name && !phone) return await getCustomers(value - 1);
                        await searchCustomers({ name, phone }, value - 1);
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CustomerTables;
