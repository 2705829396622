import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { TittlePage } from "components/TitlePage";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../../../hook/useFetch";
import { statusOptionUser } from "../../../config/configStatus";
import { getItemSearch } from "components/Search/itemSearch";
import { getItemStatus } from "components/ButtonStatus";
import appConfig from "../../../config/index";
import { AlertDialogSlide } from "components/PopUpNotification";

function UserDetailTables() {
  const navigate = useNavigate();
  const crxFetch = useFetch();
  let { id } = useParams();

  const [user, setUser] = useState({});
  const [discount, setDiscount] = useState(0);
  const [isUpdateDiscount, setIsUpdateDiscount] = useState(false);
  const [open, setOpen] = useState(false);

  const getItemUser = ({ title, value, gridNumber }) => {
    return (
      <Grid item xxl={gridNumber || 3} xl={gridNumber || 4}>
        {title && (
          <Grid item xs={12}>
            <MDBox p={1} pl={2}>
              <MDTypography sx={{ fontSize: "12px", color: "#797979" }}>{title}</MDTypography>
            </MDBox>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: "-24px" }}>
          <MDBox p={2} pb={2}>
            <MDTypography sx={{ fontSize: "12px", color: "#000000" }}>{value}</MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  const getUserDetail = async () => {
    try {
      await crxFetch.get(`${appConfig.users}/admin/${id}/detail`).then(async (res) => {
        setUser(res);
        if (res.status === "ACTIVE")
          await getUserCommission({ name: res.fullName, phone: res.phone, user: res });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getUserCommission = async ({ name, phone, user }) => {
    try {
      const complete = crxFetch.get(
        `${appConfig.commissions}?name=${name}&phone=${phone}&status=CHO_THANH_TOAN`
      );
      const waiting = crxFetch.get(
        `${appConfig.commissions}?name=${name}&phone=${phone}&status=CHUA_THANH_TOAN`
      );

      await Promise.all([complete, waiting]).then((res) => {
        setUser({
          ...user,
          commission: {
            completed: res[0].totalRecord,
            totalMoneyCompleted: res[0].data.reduce(
              (accumulator, currentValue) => accumulator + currentValue.price,
              0
            ),
            waiting: res[1].totalRecord,
            totalMoneyWaiting: res[1].data.reduce(
              (accumulator, currentValue) => accumulator + currentValue.price,
              0
            ),
          },
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const updateDiscount = async () => {
    try {
      crxFetch
        .put(`${appConfig.users}/${user.id}/update-discount?discount=${discount}`)
        .then((res) => {
          setUser({ ...user, discount });
          setIsUpdateDiscount(false);
          alert("Cập nhật thành công chiết khấu.");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const updateStatusUser = async (status) => {
    try {
      crxFetch
        .put(`${appConfig.users}/admin/${user.id}/update-status?status=${status}`)
        .then((res) => {
          setUser({ ...user, status });
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefuse = async () => {
    setOpen(true);
  };

  const handleClickAgree = async () => {
    await updateStatusUser("CANCEL");
    setOpen(false);
  };

  const handleApprove = async () => {
    await updateStatusUser("ACTIVE");
  };

  const watchOrder = async () => {
    console.log("watch order");
  };

  const watchHistoryCommissions = async () => {
    console.log("watch history watchHistoryCommissions");
  };

  const getTitle = (status) => {
    return user.status === "ACTIVE" ? "Đã kích hoạt" : "Chưa kích hoạt";
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  console.log(user);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {user.id && (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            {user.status === "INACTIVE" &&
              TittlePage({
                title: `CTV/Đại lý - ${id} - ${getTitle(user.status)}`,
                back: "/user",
                create: true,
                titleCreate: "Từ chối",
                colorCreate: "light",
                handleSave: handleRefuse,
                isDelete: true,
                titleDelete: "Phê duyệt",
                handleDelete: handleApprove,
                width: "150px",
              })}
            {user.status === "ACTIVE" &&
              TittlePage({
                title: `CTV/Đại lý - ${id} - ${getTitle(user.status)}`,
                back: "/user",
                // create: true,
                // titleCreate: "Xem đơn hàng",
                // colorCreate: "light",
                // handleSave: watchOrder,
                // isDelete: true,
                // titleDelete: "Lịch sử hoa hồng",
                // colorDelete: "light",
                // handleDelete: watchHistoryCommissions,
                // width: "170px",
              })}
            {user.status === "CANCEL" &&
              TittlePage({
                title: `CTV/Đại lý - ${id} - Đã hủy`,
                back: "/user",
                width: "170px",
              })}

            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {titleItem("Thông tin cơ bản")}
                  {getItemUser({
                    title: "Trạng thái",
                    value: getItemStatus({ statusOption: statusOptionUser, value: user.status }),
                  })}
                  {getItemUser({ title: "Mã CTV/Đại lý", value: user.fullName })}
                  {getItemUser({ title: "Họ tên", value: user.userName })}
                  {getItemUser({ title: "Số điện thoại", value: user.phone })}
                  {getItemUser({ title: "Địa chỉ", value: user.address })}
                  {getItemUser({ title: "Công việc hiện tại", value: user?.currentJob })}
                  {getItemUser({ title: "Số năm kinh nghiệm", value: user?.yearOfExperience })}
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {titleItem("Thông tin chiết khấu")}
                  {isUpdateDiscount
                    ? getItemSearch({
                        title: "Chiết khấu",
                        setState: setDiscount,
                        type: "number",
                        step: 0.1,
                        gridNumber: 2,
                      })
                    : getItemUser({ title: "Chiết khấu", value: user.discount, gridNumber: 2 })}
                  {user.status === "ACTIVE" && (
                    <Grid item xxl={3} xl={4}>
                      <Grid item xs={12} sx={{ marginTop: "-36px" }}>
                        <MDBox p={2} pb={2} mt={isUpdateDiscount ? 4 : 0}>
                          {isUpdateDiscount ? (
                            <MDButton
                              variant="contained"
                              color="error"
                              sx={{ marginTop: "20px", width: "100px" }}
                              onClick={async () => await updateDiscount()}
                            >
                              Lưu
                            </MDButton>
                          ) : (
                            <MDButton
                              variant="contained"
                              color="error"
                              sx={{ marginTop: "20px" }}
                              onClick={() => setIsUpdateDiscount(true)}
                            >
                              Chỉnh sửa
                            </MDButton>
                          )}
                        </MDBox>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card className="card_title_container">
                <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                  {titleItem("CCCD/CMNN")}
                  <Grid item xs={12}>
                    <Grid container spacing={6} sx={{ paddingTop: "0px" }}>
                      {getItemUser({ title: "Số CCCD/CMND", value: "?!" })}
                      {getItemUser({ title: "Ngày cấp", value: "?!" })}
                      {getItemUser({ title: "Nơi cấp", value: user?.address })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={6} sx={{ paddingTop: "0px" }}>
                      {getItemUser({
                        title: "Ảnh mặt trước",
                        value: (
                          <img
                            style={{ display: "block", width: "100%" }}
                            src="https://danviet.mediacdn.vn/296231569849192448/2023/2/16/anh-ha-noi-16765416636961558612282.jpg"
                          />
                        ),
                      })}
                      {getItemUser({
                        title: "Ảnh mặt sau",
                        value: (
                          <img
                            style={{ display: "block", width: "100%" }}
                            src="https://danviet.mediacdn.vn/296231569849192448/2023/2/16/anh-ha-noi-16765416636961558612282.jpg"
                          />
                        ),
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {user.status === "ACTIVE" && (
              <>
                <Grid item xs={12}>
                  <Card className="card_title_container">
                    <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                      {titleItem("Thông tin đơn hàng")}
                      {getItemUser({ title: "Đã hoàn thành", value: user?.commission?.completed })}
                      {getItemUser({ title: "Đang nhận", value: user?.commission?.waiting })}
                      <Grid item xs={2} md={4} sx={{ marginTop: "-48px " }}>
                        <MDBox pt={2} pb={2} sx={{ display: "flex" }}>
                          <MDButton
                            variant="outlined"
                            color="error"
                            sx={{
                              marginTop: "34px",
                              width: "170px",
                              color: "#D31C23",
                              fontSize: "14px",
                            }}
                            onClick={() => navigate("/product/create")}
                          >
                            Xem đơn hàng
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card className="card_title_container">
                    <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                      {titleItem("Thông tin hoa hồng")}
                      {getItemUser({
                        title: "Đã hoàn thành",
                        value: user?.commission?.totalMoneyCompleted,
                      })}
                      {getItemUser({
                        title: "Đang chờ",
                        value: user?.commission?.totalMoneyWaiting,
                      })}
                      <Grid item xs={2} md={4} sx={{ marginTop: "-48px" }}>
                        <MDBox pt={2} pb={2} sx={{ display: "flex" }}>
                          <MDButton
                            variant="outlined"
                            color="error"
                            sx={{
                              marginTop: "34px",
                              width: "170px",
                              color: "#D31C23",
                              fontSize: "14px",
                            }}
                            onClick={() => navigate("/product/create")}
                          >
                            Lịch sử hoa hồng
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      )}
      <AlertDialogSlide
        open={open}
        setOpen={setOpen}
        handleClickAgree={handleClickAgree}
        title="Xóa sản phẩm"
        description="Bạn có chắc chắn muốn xóa không?"
      />
    </DashboardLayout>
  );
}

export default UserDetailTables;
