import axios from "axios";
import { useNavigate } from "react-router-dom";
import appConfig from "../config";

export const useFetch = () => {
  let token = JSON.parse(localStorage.getItem("token_shand_viet_nam"));
  const navigate = useNavigate();

  const crxFetch = axios.create({
    baseURL: appConfig.baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  crxFetch.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["version"] = "1.1.1";
      } else {
        navigate("/authentication/sign-in");
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  crxFetch.interceptors.response.use(
    (res) => {
      return res.data;
    },
    async (err) => {
      if (err.response.status === 401) {
        return navigate("/authentication/sign-in");
      }

      if (err.response) {
        return Promise.reject(err);
      }
    }
  );

  return crxFetch;
};
