import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useFetch } from "../../hook/useFetch";
import appConfig from "../../config/index";
import { Link } from "react-router-dom";
import { getUrlSearch } from "components/Search/itemSearch";
import { getItemStatus } from "components/ButtonStatus";
import { statusOptionOrder } from "../../config/configStatus";

export default function orderData() {
  const crxFetch = useFetch();

  const [orders, setOrders] = useState([]);
  const [pages, setPages] = useState(0);

  const setRows = (item) => {
    return {
      Stt: <MDBox ml={-1}>{item.id}</MDBox>,
      "Mã đơn hàng": (
        <Link
          ml={-1}
          to={`/order/${item.id}?status=${
            statusOptionOrder.find((status) => status.title === item.statusOrder)?.value
          }&orderCode=${item.orderCode}&userId=${item.user?.id}&customer=${JSON.stringify(
            item.customer
          )}`}
          style={{ color: "#2962ff" }}
        >
          {item.orderCode}
        </Link>
      ),
      "CTV/Đại lý": <MDBox ml={-1}>{item.user?.fullName}</MDBox>,
      "Ngày giao việc": <MDBox ml={-1}>{item.createDate}</MDBox>,
      "Tên khách hàng": <MDBox ml={-1}>{item?.customer?.fullName}</MDBox>,
      "SĐT khách hàng": <MDBox ml={-1}>{item?.customer?.phone}</MDBox>,
      "Tổng giá trị đơn hàng": <MDBox ml={-1}>{item.principal?.toLocaleString("en-US")}</MDBox>,
      "Loại lệnh": <MDBox ml={-1}>{item.orderType}</MDBox>,
      "Trạng thái": getItemStatus({
        statusOption: statusOptionOrder.map((item) => {
          return { ...item, value: item.title };
        }),
        value: item.statusOrder,
      }),
    };
  };

  const searchOrders = (data, page) => {
    const urlSearch = getUrlSearch(data);
    try {
      crxFetch
        .get(`${appConfig.orders}${urlSearch}size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          setOrders(rows);
          if (pages !== res.totalPage) setPages(res.totalPage);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getOrders = async (page) => {
    try {
      await crxFetch
        .get(`${appConfig.orders}?size=${appConfig.sizePage}&page=${page}`)
        .then((res) => {
          const rows = res.data.map((item) => {
            return setRows(item);
          });
          setOrders(rows);
          if (pages !== res.totalPage) setPages(res.totalPage);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrders(0);
  }, []);

  return {
    columns: [
      { Header: "Stt", accessor: "Stt", width: "5%", align: "center" },
      { Header: "Mã đơn hàng", accessor: "Mã đơn hàng", width: "15%", align: "center" },
      { Header: "CTV/Đại lý", accessor: "CTV/Đại lý", width: "15%", align: "center" },
      { Header: "Ngày giao việc", accessor: "Ngày giao việc", width: "15%", align: "center" },
      { Header: "Tên khách hàng", accessor: "Tên khách hàng", width: "20%", align: "center" },
      { Header: "SĐT khách hàng", accessor: "SĐT khách hàng", width: "15%", align: "center" },
      {
        Header: "Tổng giá trị đơn hàng",
        accessor: "Tổng giá trị đơn hàng",
        width: "15%",
        align: "center",
      },
      { Header: "Loại lệnh", accessor: "Loại lệnh", width: "15%", align: "center" },
      { Header: "Trạng thái", accessor: "Trạng thái", width: "15%", align: "center" },
    ],
    rows: orders,
    searchOrders,
    pages,
    getOrders,
  };
}
