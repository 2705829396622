import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getItemSearch } from "components/Search/itemSearch";
import { statusOptionAttribute } from "../../../config/configStatus";
import { TittlePage } from "components/TitlePage";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";
import { useState } from "react";

function CreateAttribute() {
  const navigate = useNavigate();
  const crxFetch = useFetch();

  const indexStatusOptionAttribute = statusOptionAttribute.map((item, index) => {
    return { ...item, value: index };
  });

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const createAttribute = async (data) => {
    try {
      await crxFetch.post(appConfig.attributes, data).then((res) => {
        navigate("/attribute");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    setIsDisabled(true);
    await createAttribute({ name, code, status });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({
            title: "Thêm thuộc tính sản phẩm",
            back: "/attribute",
            create: true,
            handleSave,
            isDisabled,
          })}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thông tin thuộc tính sản phẩm")}
                {getItemSearch({ title: "Tên thuộc tính sản phẩm", setState: setName })}
                {getItemSearch({ title: "Mã thuộc tính sản phẩm", setState: setCode })}
                {getItemSearch({
                  title: "Trạng thái",
                  setState: setStatus,
                  type: "select",
                  optionSelect: indexStatusOptionAttribute,
                  valueState: status,
                })}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateAttribute;
