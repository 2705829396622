import MDBox from "components/MDBox";

export const getItemStatus = ({ statusOption, value }) => {
  const dataItem = statusOption.find((item) => item.value === value);
  return (
    <MDBox
      ml={-1}
      sx={{
        display: "inline-flex",
        backgroundColor: dataItem.backgroundColor,
        borderRadius: "10px",
        color: "#fff",
        padding: "6px 9px",
        justifyContent: "center",
      }}
    >
      {dataItem.title}
    </MDBox>
  );
};
