const appConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  sizePage: 10,
  signIn: "/auth/signin",
  orders: "/order",
  products: "/product",
  productCategory: "/product-category/admin",
  createCategory: "/product-category",
  attributeCategory: "/product/attribute",
  users: "/user",
  customers: "/customer",
  commissions: "/commission",
  productAttributes: "/product/product-attribute",
  attributes: "product/attribute",
  productMapping: "product/product-mapping",
};

export default appConfig;
