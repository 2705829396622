import MDBox from "components/MDBox";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../../../hook/useFetch";
import appConfig from "../../../config/index";

export default function orderDetailData() {
  const crxFetch = useFetch();
  let { id } = useParams();

  const [order, setOrder] = useState({});
  const [rows, setRows] = useState([]);

  const getOrderDetail = async () => {
    try {
      await crxFetch.get(`${appConfig.orders}/${id}/detail`).then((res) => {
        const dataRows = res.data.map((item) => {
          let valueAttrs = "";
          item.product?.attrs?.map((attr) => {
            valueAttrs +=
              attr.attrs != null && attr.attrs.length > 0 ? attr.attrs[attr.itemSelect] + "," : "";
          });
          let priceValue = item.product?.productPriceDto?.priceDiscount ?? item.price;
          return {
            "Mã sản phẩm": (
              <Link ml={-1} to={`/product/${item.product.code}`} style={{ color: "#2962ff" }}>
                {item.product.code}
              </Link>
            ),
            "Tên sản phẩm": <MDBox ml={-1}>{item.product.name}</MDBox>,
            "Phân loại": <MDBox ml={-1}>{valueAttrs}</MDBox>,
            "Đơn giá": <MDBox ml={-1}>{priceValue.toLocaleString("en-US")}</MDBox>,
            "Số lượng": (
              <MDBox ml={-1}>
                {item.realQuantity ? `${item.realQuantity} (${item.quantity})` : item.quantity}
              </MDBox>
            ),
            "Thành tiền": (
              <MDBox ml={-1}>
                {item.realPrincipal
                  ? `${item.realPrincipal.toLocaleString("en-US")} (${item.price.toLocaleString(
                      "en-US"
                    )})`
                  : item.price.toLocaleString("en-US")}
              </MDBox>
            ),
          };
        });
        setOrder(res);
        setRows(dataRows);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  return {
    columns: [
      { Header: "Mã sản phẩm", accessor: "Mã sản phẩm", width: "11%", align: "center" },
      { Header: "Tên sản phẩm", accessor: "Tên sản phẩm", width: "25%", align: "center" },
      { Header: "Phân loại", accessor: "Phân loại", width: "15%", align: "center" },
      { Header: "Số lượng", accessor: "Số lượng", width: "9%", align: "center" },
      { Header: "Đơn giá", accessor: "Đơn giá", width: "20%", align: "center" },
      { Header: "Thành tiền", accessor: "Thành tiền", width: "9%", align: "center" },
    ],
    rows,
    order,
  };
}
