import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import createAttributeData from "layouts/product/create/attribute/createAttributeData";
import createPricingMappingData from "layouts/product/create/attribute/createPricingMappingData";
import { TittlePage } from "components/TitlePage";
import { classificationItem } from "../attribute/classificationItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../hook/useFetch";
import appConfig from "../../../../config/index";
import { ItemAutocomplete } from "components/AutoComplete";
import { AlertDialogSlide } from "components/PopUpNotification";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function CreateAttributeProduct() {
  const navigate = useNavigate();
  let { id } = useParams();
  const crxFetch = useFetch();
  const [open, setOpen] = useState(false);
  const [productCode, setProductCode] = useState("");

  const createProduct = async (data) => {
    try {
      await crxFetch.post(appConfig.attributeCategory, data);
    } catch (err) {
      console.log(err);
    }
  };

  const [product, setProduct] = useState({});

  const getProductDetail = async () => {
    try {
      await crxFetch.get(`${appConfig.products}/${id}`).then((res) => {
        setProduct(res);
        setProductCode(res.code);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    console.log(classifications);

    await Promise.all(
      classifications.map(async (item) => {
        Promise.all(
          item.arrayAttr.map(async (attr) => {
            await createProduct({
              productId: id,
              name: item.value,
              value: attr.value,
              price: attr.price,
              status: 1,
            });
          })
        );
      })
    );
    navigate("/product");
  };

  const handleDelete = async () => {
    setOpen(true);
  };

  const handleClickAgree = async () => {
    await deletePricingMapping();
    setOpen(false);
  };

  const {
    columns,
    rows,
    classifications,
    addNewClassification,
    addItemAttr,
    setClassifications,
    getCreateOrder,
    deleteClassification,
    appliesToAll,
  } = createAttributeData();

  const [priceFast, setPriceFast] = useState("");
  const [classification, setClassification] = useState("");
  const [productAttributes, setProductAttributes] = useState([]);

  const {
    pricingMappingColumns,
    pricingMappingRows,
    pricingMappings,
    setPricingMappings,
    updateColumnName,
    deletePricingMapping,
  } = createPricingMappingData({ id, classifications, handleDelete });

  const getAttributes = async () => {
    try {
      crxFetch.get(appConfig.attributes).then((res) => {
        setProductAttributes(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getProductAttribute = async () => {
    try {
      crxFetch.get(appConfig.productAttributes + "?productId=" + id).then((res) => {
        setClassifications(res);
        updateColumnName(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const addAttribute = async (classification) => {
    if (classifications.length < 4) {
      let itemExist = false;
      classifications.forEach((item) => {
        if (item.code == classification.code) {
          itemExist = true;
        }
      });
      if (!itemExist) {
        const data = {
          productId: id,
          code: classification.code,
          status: 1,
        };

        try {
          crxFetch.post(appConfig.productAttributes, data).then((res) => {
            addNewClassification(classification);
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const deleteAttribute = async (index) => {
    const item = classifications[index];
    if (item.id) {
      crxFetch.delete(appConfig.productAttributes + `/${item.id}`).then((res) => {
        deleteClassification(index);
      });
    } else {
      deleteClassification(index);
    }
  };

  const titleItem = (title) => {
    return (
      <Grid item xs={12} className="title_background_container">
        <MDTypography sx={{ fontSize: "16px", color: "#000000" }}>{title}</MDTypography>
      </Grid>
    );
  };

  const onEdit = (id) => {};

  useEffect(() => {
    getProductDetail();
    getAttributes();
    getProductAttribute();

    console.log("Value of yourObject pricingMappingRows:", pricingMappingRows);
    console.log("Value of yourObject pricingMappingColumns:", pricingMappingColumns);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {TittlePage({
            title: "Thêm thuộc tính sản phẩm",
            back: `/product/${id}`,
            create: true,
            handleSave,
          })}

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Danh sách thuộc tính sản phẩm")}
                <MDBox
                  p={2}
                  pb={4}
                  sx={{ display: "flex", alignItems: "center" }}
                  className="input_autocomplete_search"
                >
                  {ItemAutocomplete({
                    options: productAttributes.map((item) => ({
                      label: item.name,
                      ...item,
                    })),
                    setState: setClassification,
                    title: "Chọn thuộc tính",
                    width: 200,
                  })}
                </MDBox>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                >
                  <Grid item xs={12}>
                    <MDButton
                      variant="outlined"
                      color="error"
                      sx={{
                        height: "35px",
                        color: "#D31C23",
                        border: "1px solid #D31C23",
                        borderRadius: "7px",
                        marginBottom: "24px",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        addAttribute(classification);
                      }}
                    >
                      <Icon
                        sx={{
                          scale: "1.3",
                          marginRight: "4px",
                          color: "#D31C23",
                          fontWeight: 500,
                        }}
                      >
                        add
                      </Icon>
                      Thêm thuộc tính
                    </MDButton>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ margin: "16px" }}>
                  {classifications.map((classification, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                      <Card sx={{ position: "relative", border: "1px solid rgb(227 221 221)" }}>
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "#D9D9D9",
                            borderRadius: "50%",
                            zIndex: 1,
                          }}
                          onClick={() => deleteAttribute(index)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                        {classificationItem({
                          index,
                          classifications,
                          setClassifications,
                          addItemAttr,
                          getCreateOrder,
                        })}
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="card_title_container">
              <Grid container spacing={6} sx={{ marginBottom: "-24px", marginLeft: "-24px" }}>
                {titleItem("Thiết lập giá")}

                <MDButton
                  variant="outlined"
                  color="error"
                  sx={{
                    height: "35px",
                    color: "#D31C23",
                    border: "1px solid #D31C23",
                    borderRadius: "7px",
                    marginBottom: "24px",
                    fontSize: "14px",
                    position: "absolute",
                    top: "10px", // Adjust this value for vertical positioning
                    right: "20px", // Adjust this value for horizontal positioning
                  }}
                  onClick={() => {
                    const item = {
                      productId: product.id,
                    };
                    navigate(`/product/edit/product-mapping?item=${JSON.stringify(item)}`);
                  }}
                >
                  <Icon
                    sx={{
                      scale: "1.3",
                      marginRight: "4px",
                      color: "#D31C23",
                      fontWeight: 500,
                    }}
                  >
                    add
                  </Icon>
                  Thêm mới mapping giá
                </MDButton>

                {+pricingMappingRows.length > 0 && (
                  <DataTable
                    table={{ columns: pricingMappingColumns, rows: pricingMappingRows }}
                    isSorted={false}
                    entriesPerPage={{
                      defaultValue: 50,
                      entries: [5, 10, 15, 20, 25, 50],
                    }}
                    showTotalEntries={false}
                    noEndBproduct
                  />
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <AlertDialogSlide
        open={open}
        setOpen={setOpen}
        handleClickAgree={handleClickAgree}
        title="Xóa thiết lập giá"
        description="Bạn có chắc chắn muốn xóa không?"
      />
    </DashboardLayout>
  );
}

export default CreateAttributeProduct;
