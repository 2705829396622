import MDInput from "components/MDInput";
import MenuItem from "@mui/material/MenuItem";

export const selectOption = ({ index, value, handleChange, optionSelect, minWidth, indexAttr }) => {
  return (
    <MDInput
      className="select_option"
      variant="standard"
      select
      labelid="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      InputProps={{
        classes: { root: "select-input-styles" },
      }}
      fullWidth
      sx={{ minWidth: minWidth || "170px", margin: "-8px", marginBottom: "-20px" }}
      onChange={(e) => handleChange(e.target.value, index, indexAttr)}
    >
      {optionSelect.map((option, i) => (
        <MenuItem key={i} value={option.value} className="menu_item">
          {option.title}
          {option.price && (
            <p style={{ color: "green", fontSize: "10px" }}>
              +{option.price?.toLocaleString("en-US")}
            </p>
          )}
        </MenuItem>
      ))}
    </MDInput>
  );
};
